import React from 'react';
import './blogs.css'
import crime from '../../assets/crime.jpg'

function Blogs() {

    return (
        <>
            <div className="blog1">
                <div className="first">
                    <div className="bchat-overlay">
                        <p className="one">The Rise of Digital Arrest Scams</p>
                    </div>
                </div>
                <div className="blog-grad"></div>
                <img src={crime} alt="solution" className="responsive-image" />
            </div>

            <div className="blogp1">
                <h3>The Rise of Digital Arrest Scams: What
                    You Need to Know to Stay Safe</h3>
                <p className="p1text"> Have you ever received a suspicious call claiming your package contains illegal items or your bank
                    account is involved in suspicious transactions? In many cases, the digital arrest scam starts with a
                    simple phone call that appears to be from one of these sources. The call may be either direct or a
                    recorded message requesting the recipient to press a number for further details. Once connected, a
                    scammer claiming to be a support representative initiates the fraudulent scheme.</p>
            </div>
            
            <div style={{ backgroundColor: '#F8F9FA', padding: '20px' }}>
                <div className="blogp2">
                    <h3>How the Scam Works</h3>
                    <p className="p21text">  The fraudsters often gather some personal information about the victim, such as Aadhaar (or its last four
                        digits), PAN, or bank details. They may acquire this information through data breaches, phishing, or
                        other malicious means, and then use it to gain the victim's trust and make their claims sound more
                        convincing. Victims are told that their Aadhaar, PAN, mobile, or bank account has been used for illegal
                        activities, such as money laundering or financing terrorism. The scammers escalate fear by threatening
                        victims with immediate arrest or severe consequences, increasing the pressure to comply. The scammers
                        use these claims to instill fear, making victims believe they are in serious trouble, which compels them to
                        comply with further demands in hopes of clearing their name. The next step involves connecting the
                        victim to impersonators posing as officials from law enforcement agencies</p>
                    <br /><p className="p22text">These impersonators claim to be from agencies such as the police, Customs, Enforcement Directorate
                        (ED), or Central Bureau of Investigation (CBI). They may issue arrest warrants during a video call or
                        through WhatsApp, further escalating the pressure.</p>
                    <br /><p className="p23text">
                        Digital arrest refers to the virtual confinement of victims, which may include restricted access to
                        accounts, prevention of digital activities, continuous live monitoring through video calls, and periodic
                        reporting to the scammers. The situation continues until the victim complies with the scammers' demands
                        for money, often under escalating threats of severe consequences, such as arrest or freezing of accounts,
                        or imposed time limits to create a sense of urgency. A typical approach includes taking the victim to a
                        so-called virtual court on a Skype call while under digital arrest. Victims are led to believe that they must
                        transfer their bank balance to an account that will be verified and returned within 30 minutes. Fake letters
                        on official-looking letterheads and fraudulent receipts are also used to create a sense of legitimacy. These
                        documents often claim to be from authorities like the police, tax departments, or financial regulators,
                        making the scam appear more authentic.
                    </p>
                </div>
            </div>
            <div className="blog3">
                <div className="blog31">
                    <h3>Role of Banks and Government</h3>
                    <p>
                        Banks can play a crucial role in fighting against the digital arrest scam by enhancing fraud detection
                        systems, such as using AI-driven transaction monitoring, and educating their customers about such
                        threats. Banks should implement real-time monitoring of suspicious transactions, especially those
                        involving large sums under unusual circumstances. Additionally, providing clear communication to
                        customers about common scam tactics, such as impersonation and fake warrants, can help increase
                        awareness. Training bank employees to recognize signs of potential fraud and offer support to victims can
                        also help prevent these scams from succeeding
                    </p>
                    <br /> <p>
                        Banks and the government can work together to strengthen efforts against these scams by sharing data
                        on suspicious activities, setting up joint response teams, and collaborating on awareness campaigns. By
                        sharing data on emerging threats and coordinating on awareness campaigns, they can reach a broader
                        audience and enhance the overall response to digital scams. Collaborative efforts, such as developing
                        joint protocols for rapid response to fraud incidents and sharing best practices, can make it more difficult
                        for scammers to operate. Additionally, the government can provide regulatory support to ensure banks
                        have the tools and authority needed to freeze suspicious transactions and protect customers
                    </p>
                </div>
            </div>
            <div className="blog4">
                <div className="blog41">
                    <h3>Real-Life Example</h3>
                    <p> The scammers exploit the ignorance, fear, anxiety, or blind trust of their targets. Victims can safeguard
                        themselves by verifying claims through official websites or contacting trusted numbers to ensure the
                        legitimacy of any information. Under such mental pressure, victims may lose their ability to think clearly
                        and act rationally. To protect themselves, victims should pause the conversation, take a deep breath, and
                        consult trusted friends or family members before taking any action. Additionally, any suspicious calls
                        should be reported immediately to the cybercrime helpline for further support. In a recent incident, the
                        Chairman and Managing Director of a leading textile group in India was defrauded by scammers
                        impersonating officials from various government agencies. The scammers even conducted a virtual court
                        session, with an impersonator acting as the Chief Justice of India</p>
                </div>
                <div className="blog41">
                    <h3> Advisory from Authorities
                    </h3>
                    <p className="p21text">  In response to the increasing number of incidents, the Indian Cyber Crime Coordination Centre (I4C),
                        affiliated with the Ministry of Home Affairs, released an advisory. The advisory urged people to "Don't
                        Panic, Stay Alert." The advisory emphasized that "CBI/Police/Custom/ED/Judges DO NOT arrest you on
                        video call." Courier agencies have issued guidelines warning people to stay away from such scams. For
                        instance, FedEx clarified that it never requests payments or personal information through unsolicited
                        communications</p>
                    <br /><p className="p22text"> FedEx further clarified that it is not associated, affiliated with, or authorized to act on behalf of any
                        national or local law enforcement authorities. If someone claiming to be from FedEx connects you to fake law enforcement authorities about any shipment containing illegal items in your name, do not share any
                        personal information. If such interaction has led to financial loss, report it immediately by calling 1930 or
                        visiting <a className='link' href="http://www.cybercrime.gov.in/">http://www.cybercrime.gov.in/</a>
                    </p>
                </div>
            </div>
            <div className="blog3">
                <div className="blog31">
                    <h3> Challenges and Solutions
                    </h3>
                    <p>
                        Despite multiple awareness campaigns from various agencies, fraudulent digital arrests and extortion
                        attempts continue to occur. This persistence is largely due to the evolving tactics of scammers, such as
                        using deepfake videos and fake documents, and the lack of widespread digital literacy among the public
                    </p>
                    <br /> <p>
                        Generative AI can be used in the fight against these scams by detecting deepfake content and helping
                        authorities develop more sophisticated tools for identifying scam calls. For example, generative AI has
                        been successfully used to analyze and flag deepfake videos in real-time, helping to prevent their use in
                        scams. GenAI-driven chatbots can also be used to educate people about scam tactics and guide victims
                        on how to respond safely. The best way to avoid becoming a victim is to stay vigilant, have open
                        discussions with friends and colleagues, or consult an information security team if in doubt.
                    </p>
                </div>
            </div>
            <div className="blog3">
                <div className="blog31">
                    <h3>Government Initiatives
                    </h3>
                    <p>
                        As a preventive measure, the government recently launched the Chakshu portal <br />
                        <a className='link' href="https://sancharsaathi.gov.in/sfc/Home/sfc-complaint.jsp">https://sancharsaathi.gov.in/sfc/Home/sfc-complaint.jsp</a> under the Sanchar Sathi initiative of the
                        Department of Telecom. The Sanchar Sathi initiative aims to enhance the security and safety of telecom
                        users by providing tools to track, block, and report fraudulent activities. Chakshu allows citizens to report
                        suspected fraudulent communications aimed at defrauding telecom service users for cybercrime, financial
                        frauds, or other malicious purposes via calls, SMS, or WhatsApp.                    </p>
                    <br /> <p>
                        In case you fall victim to cybercrime, you can register complaints through the National Cybercrime
                        Reporting Portal (NCRP) at <a className='link' href="https://cybercrime.gov.in">https://cybercrime.gov.in</a> Financial cyber frauds can also be reported by
                        calling the National Cybercrime Helpline number 1930
                    </p>
                </div>
            </div>
            <div style={{ backgroundColor: '#F8F9FA', padding: '20px' }}>
                <div className="blogp2">
                    <h3>Conclusion</h3>
                    <p className="p21text"> Staying informed and vigilant is your best defense against these scams. Make sure to spread the word
                        and help others stay safe from these deceitful tactics</p>
                </div>
            </div>
        </>
    )
}

export default Blogs
