import React from "react";
import './dataAnalytics.css';
import banner from '../../../assets/image 30.png'
import ingestion from '../../../assets/ingestion.png'
import trans from '../../../assets/data-transformation.png'
import insights from '../../../assets/data-insights.png'
import visual from '../../../assets/data-visualization-icon.png'
import reports from '../../../assets/reports-icon.png'
import budget from '../../../assets/budget-icon.png'
import datalake from '../../../assets/lake.png'
import dashboard from '../../../assets/dashboard.png'
import model from '../../../assets/model.png'
import deploy from '../../../assets/deploy.png'
import chatbot from '../../../assets/chatbot.png'
import machine from '../../../assets/image 39.png'
import vision from '../../../assets/image 38.png'
import ocr from '../../../assets/image 37.png'
import edge from '../../../assets/edge.png'

function DataAnalytics() {
    
    return (
        <>
            <div className="data_container">
                <div className="gradient-data"></div>
                <img src={banner} alt="Backgroundimage" />
                <div className="data_text">
                    <p className="one">Data <br />Analytics</p>
                </div>
            </div>
            <div className="data_section1">
                <h4>End-to-End Data Analytics and AI/ML Solutions and Services</h4>
                <p>At Deep Algorithms, we have fully automated the ingestion and integration of complex<br />
                    Health-Care/Life-Science datasets from various sources and created Dashboards/Reports to get insights.</p>
            </div>

            <div className="data_need">
                <div className="data_card">
                    <img src={ingestion} alt="ingestion" className="ing_image" />
                    <h6 className="ing_text">Data ingestion</h6>
                </div>
                <div className="data_card">
                    <img src={trans} alt="trans" className="trans_image" />
                    <h6 className="trans_text">Data Transformation</h6>
                </div>
                <div className="data_card">
                    <img src={insights} alt="insights" className="insights_image" />
                    <h6 className="insights_text">Data Insights and Prediction</h6>
                </div>
                <div className="data_card">
                    <img src={visual} alt="visual" className="visual_image" />
                    <h6 className="visual_text">Data Visualization <br />Efficacy Report</h6>
                </div>
                <div className="data_card">
                    <img src={reports} alt="reports" className="reports_image" />
                    <h6 className="reports_text">Patients Persistency Reports <br />and Dashboard</h6>
                </div>
                <div className="data_card">
                    <img src={budget} alt="budget" className="budget_image" />
                    <h6 className="budget_text">Sales and Marketing Budget <br />Allocation Optimization</h6>
                </div>

            </div>

            <div className="data_need1">
                <div className="data_card1">
                    <img src={datalake} alt="datalake" width="80" className="datalake_image" />
                    <h6 className="datalake_text">Data Lake <br />Strategy</h6>
                </div>
                <div className="data_card1">
                    <img src={dashboard} alt="dashboard" width="80" className="dashboard_image" />
                    <h6 className="datalake_text">Dashboard & <br />Reporting</h6>
                </div>
                <div className="data_card1">
                    <img src={model} alt="model" width="80" className="model_image" />
                    <h6 className="datalake_text">AI/ML Model <br />Strategy</h6>
                </div>
                <div className="data_card1">
                    <img src={deploy} alt="deploy" width="80" className="deploy_image" />
                    <h6 className="datalake_text">AI Model <br />Deployment</h6>
                </div>
                <div className="data_card1">
                    <img src={chatbot} alt="chatbot" width="80" className="chatbot_image" />
                    <h6 className="datalake_text">NLP, Voice Interface <br /> and Chatbot</h6>
                </div>

            </div>

            <div className="data_section2" >
                <div className="gradient"></div>
                <div className="data-content">
                    <div className="data_text-container">
                        <h3>Machine Learning Model <br />Deployment & Serving</h3><br />
                        <p>As one of the leading machine learning and development companies, our team uses Cross-Industry Standard Process for data mining to ensure that the solution fulfill your business's requirement and objectives.<br />
                            <br />We dig deep into your project to know your requirements, and delivery a fully automated system that helps to take your business to another level.</p><br />
                    </div>
                </div>
                <img src={machine} alt="model" className="responsive_model-image" />
            </div >

            <div className="data_section3" >
                <div className="gradient3"></div>
                <img src={vision} alt="vision" className="responsive_vision-image" />
                <div className="data2-content">
                    <div className="data2_text-container" >
                        <h3> Custom Computer Vision</h3><br />
                        <p>We help you add value to your business with AI algorithms for robust computer vision solutions. Our aim is to fabricate computer vision intelligence to bring real time changes to your business tasks. we deliver high-end CV solutions extracting data from Images, live video streams and video forms help in the improvement of the workflow of the business of our clients.<br />
                            <br />We do everything including automation of visual tasks, computer vision consulting and building new products from scratch in order to improve your business. We tailor our tasks and recreate them for your unique requirements.
                        </p>
                    </div>
                </div>
            </div >

            <div className="data_section4" >
                <div className="gradient4"></div>
                <div className="data3-content">
                    <div className="data3_text-container">
                        <h3>Automated Data Capture and OCR Services</h3><br />
                        <p>Technology is increasing rapidly, and in a business, the volume of data that contains details of the business is also increasing dramatically. And many processes were becoming an integral part of the organization’s workflows. Processes like data capture, retrieval, and extraction are now becoming important in business. Also, they are essential in managing a large amount of information stored in different formats and locations.<br />
                            <br />In DeepAlgorithms, we have OCR, data capture, and location services that will manage your data and automate your business process. Depending on your requirements, DeepAlgorithms provide unique solutions.</p><br />
                    </div>
                </div>
                <img src={ocr} alt="ocr" className="responsive_ocr-image" />
            </div >

            <div className="data_section5" >
                <div className="gradient5"></div>
                <img src={edge} alt="edge" className="responsive_edge-image" />
                <div className="data4-content">
                    <div className="data4_text-container" >
                        <h3> Edge Analytics</h3><br />
                        <p>Nowadays, the technical growth of companies is becoming so fast, and the growth of connected devices is also occurring rapidly. The device in the industries such as energy, manufacturing, retail, and transportation are rating high volumes of data at the edge of the network; Edge Analytics gather stores this data in real-time and non-site irrespective of the type of analytics.<br />
                            <br />Internet of things contains the data of companies' abilities, assets, and effects to learn from it. Normally, Edge Analytics is a real-time learning approach where increasing data streams are analyzed at a non-central point in the system, such as a switch, a peripheral node, or connected devices or sensors. This edge analytics is a model of data analysis.
                        </p>
                    </div>
                </div>
            </div >
        </>


    );
}

export default DataAnalytics;