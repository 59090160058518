import React, { useEffect, useState } from 'react';
import './passwordless.css'
import back from '../../../assets/passwordless.png'
import pass from '../../../assets/PA7 1.png'
import security from '../../../assets/PA8 1.png'
import fitsp from '../../../assets/PA9 1.png'
import finance from '../../../assets/finance.png'
import health from '../../../assets/health.png'
import IT from '../../../assets/IT.png'
import government from '../../../assets/govt.png'
import retail from '../../../assets/retail.png'
import education from '../../../assets/education.png'
import tele from '../../../assets/tele.png'
import energy from '../../../assets/energy.png'
import manufacture from '../../../assets/manufacture.png'
import tranport from '../../../assets/transportation.png'
import ellipse from '../../../assets/Ellipse 702.png'
import circle from '../../../assets/Rectangle 3142.png'
import circle1 from '../../../assets/Ellipse 685.png'


function Password() {

    return (
        <>
            <div className='password_container'>
                <div className="grada"></div>
                <img src={back} alt='background' />
                <div className='right'>
                    <h4>Password-less <br />Authentication</h4>
                </div>
            </div>

            <div className="pass_section1">
                <div className="section-card_pass">
                    <h3>What is Password-less Authentication ? </h3><br />
                    <p>
                        Password-less authentication is a security approach that allows users to access systems, accounts, or devices without the use of traditional passwords. Password-less authentication uses alternate elements to validate a user's identification rather than traditional passwords. <br />
                        <br />This technique seeks to improve security by addressing the vulnerabilities associated with password-based systems, such as weak passwords, password reuse, and vulnerability to phishing attacks. Password-less authentication aims to provide a more user-friendly and safe method of validating identification, decreasing reliance on readily hacked passwords.
                    </p><br />
                </div>
                <img src={pass} alt="about" className="responsive-image_pass" />
            </div>

            <div className="pass-cyber" >
               
                    <img src={security} alt="picture1" className='security'/>
                
                <div className="right">
                    <div className="text-containerp">
                       
                            <h3> How can Password-less Authentication be used in cyber security? </h3><br />
                            <p>Password-less authentication enhances cybersecurity by eliminating the weaknesses associated with traditional passwords. It helps prevent breaches, phishing, and credential stuffing attacks.
                            </p> 

                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle1} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Securing User Access</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle1} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Enhancing Remote Work Security</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle1} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Integrating with Multi-Factor Authentication (MFA)</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle1} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Preventing Phishing and Credential Attacks</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle1} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Streamlining Authentication Processes</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle1} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Compliance and Regulatory Adherence</p>
                                        </div>
                                    </div>
                                </li>



                            </ul>
                            
                       
                    </div>
                </div>
            </div >


          <div className="fits_pass">
                <div className="grada1"></div>
                <div className="initial1">
                    <h4>Where can we use it?</h4>
                    <p className="para">Password-less authentication can be used in various environments and scenarios within the realm of cybersecurity </p>
                    <div className="cctv-list">
                        <div>
                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Corporate Networks</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Healthcare Systems</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>E-commerce Platforms</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Government Services</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Educational Institutions</p>
                                        </div>
                                    </div>
                                </li>



                            </ul>
                        </div>
                        <div className="list2">
                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Online Banking and Financial Transactions</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Telecommunications</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Personal Devices</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Critical Infrastructure Systems</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={circle} alt="circle" width="13" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Cloud Services</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src={fitsp} alt="fits" className="fitspass-image" />
            </div>

        <div className="ind_pass">
                <div className="initial2">
                    <h4>Which industries can use password-less Authentication? </h4>
                    <p>Password-less authentication can be effectively utilized across various industries, enhancing security and user convenience. </p>
                </div>
                <div className="pass_section4">
                    <div className="pass_card">
                        <img src={finance} alt="vulnerability" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="vul_text">Financial Services</h6>
                            <p>Banks and financial institutions can use it for securing online banking platforms and protecting financial transactions.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={health} alt="fraud" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="fraud_text">Healthcare</h6>

                            <p>To safeguard patient data and healthcare systems, ensuring secure access to medical records and communication channels.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={IT} alt="unlike" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="unlike_text">Information Technology</h6>

                            <p>For securing corporate networks, data centers, and IT infrastructure against unauthorized access and cyber threats.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={government} alt="cost" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="cost_text">Government and Public Sector</h6>

                            <p>Essential for protecting sensitive government data, securing communication channels, and ensuring secure access to public services.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={retail} alt="risks" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="risks_text">Retail and E-Commerce</h6>

                            <p>To enhance customer security during online transactions and protect against fraud in digital shopping environments.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={education} alt="risks" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="risks_text">Education</h6>

                            <p>For securing access to online learning platforms, student information systems, and research databases.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={tele} alt="risks" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="risks_text">Telecommunications</h6>

                            <p>In protecting network infrastructure and customer data within communication systems.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={energy} alt="risks" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="risks_text">Energy and Utilities</h6>

                            <p>Critical for safeguarding control systems in power plants, water treatment facilities, and other utility infrastructure.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={manufacture} alt="risks" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="risks_text">Manufacturing</h6>

                            <p>Especially in industries with sensitive intellectual property, ensuring that only authorized personnel access certain systems and data.</p>
                        </div>
                    </div>
                    <div className="pass_card">
                        <img src={tranport} alt="risks" className="pf_image" />
                        <div style={{ padding: '10px' }}>
                            <h6 className="risks_text">Transportation and Logistics</h6>

                            <p>For protecting operational data and systems in industries like airlines, shipping, and public transportation.</p>
                        </div>
                    </div>
                </div>
            </div > 

        </>
    )
}

export default Password;