import React from 'react';
import './socialeng.css'
//import banner from '../../../assets/Mask group.png'
import banner from '../../../assets/socialeng.png'
import social from '../../../assets/image 87.png'
import features from '../../../assets/image 88.png'
import circle from '../../../assets/Ellipse 685.png'
import cyber from '../../../assets/image 90.png'
import uses from '../../../assets/image 91.png'
import benefits from '../../../assets/image 93.png'
import cases from '../../../assets/image 94.png'

function SocialEng() {

    return (
        <>
            <div className="social-1">
                <img src={banner} alt="Backgroundimage"/>
                <div className="stext-overlay">
                    <p className="one">Social Engineering <br />Attacks Protection</p>
                </div>
            </div>

            <div className="password_section1">
                <img src={social} alt="about" className="responsive-image_password" />
                <div className="section-card_password">
                    <h3>What are Social Engineering Attacks?</h3><br />
                    <p>Social engineering attacks are manipulative techniques that exploit human error to gain access to private information, systems, or valuables.
                        <br /><br /> These attacks often involve psychological manipulation and the exploitation of human weakness to trick individuals into sharing sensitive information, downloading malicious software, or making other security-compromising mistakes.
                        <br /><br /> Social engineering attacks often use psychological manipulation to trick otherwise unaware consumers or employees into disclosing personal or sensitive data.
                    </p>
                </div>
            </div>

            <div className="soc-features">
                <div className="initial">
                    <h4>Features of Social Engineering<br /> Attacks Protection tool</h4>
                    <div className="modelprod">
                        <img src={circle} alt="banking" className="bank_image" />
                        <h6 className="bank_text">Security Awareness Training</h6>
                    </div>
                    <div className="modelprod">
                        <img src={circle} alt="banking" className="bank_image" />
                        <h6 className="bank_text">Email Security</h6>
                    </div>
                    <div className="modelprod">
                        <img src={circle} alt="banking" className="bank_image" />
                        <h6 className="bank_text">Multifactor Authentication</h6>
                    </div>
                    <div className="modelprod">
                        <img src={circle} alt="banking" className="bank_image" />
                        <h6 className="bank_text">AI-Powered Detection</h6>
                    </div>
                    <div className="modelprod">
                        <img src={circle} alt="banking" className="bank_image" />
                        <h6 className="bank_text">Defense in Depth Approach</h6>
                    </div>
                </div>
                <div className='gradientu'></div>
                <img src={features} alt="fits" className="fitsp-image" />
            </div>

            <div className="soc-cyber">
                <img src={cyber} alt="about" className="responsive-image_password" />
                <div className="section-card_password">
                    <h3>How can it be used in cyber security?</h3><br />
                    <p>Social engineering attack protection tools can be used in cybersecurity to prevent attacks that manipulate individuals into sharing sensitive information, downloading malware, or making security mistakes.
                        <br /><br />These tools typically include features such as security awareness training, email security, multifactor authentication, AI-powered detection, and a defense-in-depth approach.
                        <br /><br />By implementing these tools, organizations can enhance user awareness, secure email communications, and detect and prevent social engineering attacks, thereby reducing the risk of unauthorized access and data breaches.
                    </p>
                </div>
            </div>

            <div className="total">
                <div className="social-cyber">
                    <div className="initial">
                        <h4>Where can we use it? </h4>
                        <p>In order to stop social engineering attacks that take advantage of security flaws in people and exploit their vulnerabilities, these tools can be used in a variety of settings,</p>
                        <div className="modelprod">
                            <img src={circle} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Corporate Environments</h6>
                        </div>
                        <div className="modelprod">
                            <img src={circle} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Remote Work Environments</h6>
                        </div>
                        <div className="modelprod">
                            <img src={circle} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Financial Institutions</h6>
                        </div>
                        <div className="modelprod">
                            <img src={circle} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Healthcare Organizations</h6>
                        </div>
                        <div className="modelprod">
                            <img src={circle} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Educational Institutions</h6>
                        </div>
                    </div>
                    <div className='gradientu'></div>
                    <img src={uses} alt="fits" className="fitsp-image" />
                </div>

                <div className="social-benefits">
                    <img src={benefits} alt="benefits" />
                    <div className="benefits-text">
                        <h4>Benefits</h4>
                        <div>
                            <h5>Risk Reduction</h5>
                            <p>Security awareness training helps employees recognize and respond to social engineering threats.</p>
                        </div>
                        <div>
                            <h5>Confidence in Security</h5>
                            <p>Regular assessments and testing enhance organizational confidence in security measures.</p>
                        </div>
                        <div>
                            <h5>Holistic Security Approach</h5>
                            <p>These tools address the human element, adding depth to cybersecurity strategies.</p>
                        </div>
                        <div>
                            <h5>Employee Preparedness</h5>
                            <p>Consistent testing and training bolster employee resilience against such attacks.</p>
                        </div>
                        <div>
                            <h5>Effective Threat Mitigation</h5>
                            <p>The tools play a crucial role in mitigating risks posed by one of the biggest security threats: social engineering.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="social-ind">
                <div className="common-images">
                    <img src={cases} alt="Imagew" />
                </div>
                <div className="top">
                    <div className="content">
                        <div className="sectiont">
                            <h5>Use case in different industries</h5>
                            <p>Social Engineering Attacks Protection tools can be used in a wide range of industries</p>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="content">
                        <div className="sectionld">

                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <h6>Corporate Sector</h6>
                                    <p>Businesses across various industries can benefit from these tools to prevent social engineering attacks that target employees and sensitive information.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <h6>Financial Services</h6>
                                    <p>Given the sensitive nature of financial data, the financial sector can use these tools to safeguard against social engineering attacks.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <h6>Healthcare</h6>
                                    <p>Healthcare organizations can utilize these tools to prevent attacks that target patients' sensitive medical information.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <h6>Education</h6>
                                    <p>Educational institutions can benefit from these tools to prevent attacks that target students' sensitive information.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <h6>Remote Work Environments</h6>
                                    <p>With the rise of remote work, these tools can be particularly valuable in preventing attacks that exploit the vulnerabilities of remote workers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default SocialEng