import React from "react";
import './grc.css';
import banner from '../../../assets/banner.png'
import pic1 from '../../../assets/image 35.png'
import card1 from '../../../assets/image 31.png'
import card2 from '../../../assets/image 32.png'
import card3 from '../../../assets/image 33.png'
import card4 from '../../../assets/image 34.png'
import rect from '../../../assets/Rectangle 3164.png'
import arch from '../../../assets/Group 26.png'
import case1 from '../../../assets/Frame 24.png'


function GRC() {



    return (
        <>
            <div className="container">
                <img src={banner} alt="Backgroundimage" />
            </div>

            <p className="grc-para1">Governance, Risk and Compliance</p>

            <div className="grc_section1">
                <img src={pic1} alt="about" className="responsive-image_pene" />
                <div className="section-card_pene">
                    <p>
                        A leader in Risk management solution, RSA Archer provides a 3 dimension approach catering across the process lines of governance, risk and compliances.<br /><br />
                        RSA Archer allows complete automation of the governance, risk and compliances process with minimal manual efforts. It converges and co-relates the high-risk parameters from the respective organization and provides an enterprise view to the stakeholders of the relevant risks.                    </p>
                </div>
            </div>

            <div className="arch">
                <p>The primary segments covered under RSA Archercs</p>
                <img src={arch} alt="rect" className="rect1" />

            </div>

            {/* <div className="success">
                <div className="content">
                    <p className="head1">Success Stories</p>
                    <p className="para1">With our Dedicated GRC professional services, we enable organizations with their<br />
                        regular GRC activities thereby allowing improvised ROI of the installed application.</p>
                    <div className="card-content">
                        <div className="grc-card">
                            <div className="image-card">
                                <img src={card1} alt="vulnerability" className="vul_image" />
                                <div className="overlay-card">
                                    <div class="overlay-card-text">CASE STUDY</div>
                                </div>
                            </div>
                            <div className="card-text">
                                <h6 className="vul_text">Maecenas feugiat fermentum sem ac feugiat.</h6>
                                <p>Suspendisse posuere libero eu mauris posuere elementum. Praesent fringilla pulvinar tempor. Mauris porta auctor tortor, tincidunt vehicula libero aliquet nec. Nam pharetra felis ut turpis efficitur, in convallis lectus congue.</p>
                            </div>
                        </div>
                        <div className="grc-card">
                            <div className="image-card">
                                <img src={card2} alt="fraud" className="fraud_image" />
                                <div className="overlay-card">
                                    <div class="overlay-card-text">CASE STUDY</div>
                                </div>
                            </div>
                            <div className="card-text">
                                <h6 className="vul_text">Maecenas feugiat fermentum sem ac feugiat.</h6>
                                <p>Suspendisse posuere libero eu mauris posuere elementum. Praesent fringilla pulvinar tempor. Mauris porta auctor tortor, tincidunt vehicula libero aliquet nec. Nam pharetra felis ut turpis efficitur, in convallis lectus congue.</p>
                            </div>                        </div>
                        <div className="grc-card">
                            <div className="image-card">
                                <img src={card3} alt="fraud" className="fraud_image" />
                                <div className="overlay-card">
                                    <div class="overlay-card-text">CASE STUDY</div>
                                </div>
                            </div>
                            <div className="card-text">
                                <h6 className="vul_text">Maecenas feugiat fermentum sem ac feugiat.</h6>
                                <p>Suspendisse posuere libero eu mauris posuere elementum. Praesent fringilla pulvinar tempor. Mauris porta auctor tortor, tincidunt vehicula libero aliquet nec. Nam pharetra felis ut turpis efficitur, in convallis lectus congue.</p>
                            </div>
                        </div>
                        <div className="grc-card">
                            <div className="image-card">
                                <img src={card4} alt="fraud" className="fraud_image" />
                                <div className="overlay-card">
                                    <div class="overlay-card-text">CASE STUDY</div>
                                </div>
                            </div>
                            <div className="card-text">
                                <h6 className="vul_text">Maecenas feugiat fermentum sem ac feugiat.</h6>
                                <p>Suspendisse posuere libero eu mauris posuere elementum. Praesent fringilla pulvinar tempor. Mauris porta auctor tortor, tincidunt vehicula libero aliquet nec. Nam pharetra felis ut turpis efficitur, in convallis lectus congue.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>

    );
}

export default GRC;