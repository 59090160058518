import React, { useState } from 'react';
import './demo.css'
import dalogo from '../../assets/whitedalogo.png'
import axios from 'axios';
import { showSuccessToast, showErrorToast } from '../../toastutils';

function Demo() {

  const [formData, setFormData] = useState({
    name: '',
    request_type: "demo",
    company_name: '',
    email_id: '',
    mobile_number: '',
    message: null,
    lastname:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.deepalgorithms.in/contact', formData);
      if (response.status == 200) {
        showSuccessToast('Details submitted successfully')
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      showErrorToast('Error submitting details')
    }
    setFormData({
      name: '',
      request_type: '',
      company_name: '',
      email_id: '',
      mobile_number: '',
      message: '',
      lastname:''
    });
  };

  return (
    <>
      <div className="demo_container">
        <div className="request_text">
          <h4>Request Demo</h4>
          <p>Please submit your information for immediate contact from one of our team members.</p>
        </div>
        <div className="item">
          <div className="image">
            <img src={dalogo} alt="demo" />
          </div>
          <div className="demo_form">
            <h4>Fill out the form below</h4>

            <form id="frm" className="form" onSubmit={handleSubmit}>
              <label>First name <span className="star">*</span></label>
              <input type="text" name='name' required value={formData.name} onChange={handleChange} />
              <label>Last name <span className="star">*</span></label>
              <input type="text" name='lastname' value={formData.lastname} onChange={handleChange} />
              <label>Company<span className="star">*</span></label>
              <input type="text" name='company_name' required value={formData.company_name} onChange={handleChange} />
              <label>Email <span className="star">*</span></label>
              <input type="text" name='email_id' required value={formData.email_id} onChange={handleChange} />
              <label>Mobile <span className="star">*</span></label>
              <input type="text" name='mobile_number' required value={formData.mobile_number} onChange={handleChange} />
              <button className="button">Submit</button>
            </form>
           
          </div>

        </div>

      </div>
    </>
  );
}

export default Demo;