import React, { useEffect, useState } from 'react';
import './cloud.css';
import cloud from '../../../assets/image 50.png'
import pic from '../../../assets/Rectangle 3201.png'
import iconc from '../../../assets/cloud-icon.png'
import micro from '../../../assets/micro-services.png'
import mobile from '../../../assets/image 54.png'
import circle from '../../../assets/Ellipse 685.png'
import web from '../../../assets/web-app-illustration.png'
import ellipse from '../../../assets/Ellipse 700.png'

function Cloud() {

    const [cloud2Height, setCloud2Height] = useState(0);
    const [cloud3Height, setCloud3Height] = useState(0);
    const [cloud4Margin, setCloud4Margin] = useState(50); // Adjust this value as needed

    useEffect(() => {
        adjustCloud4Margin();
        window.addEventListener("resize", adjustCloud4Margin);

        return () => {
            window.removeEventListener("resize", adjustCloud4Margin);
        };
    }, [cloud2Height, cloud3Height]);

    function adjustCloud4Margin() {
        const cloud2Element = document.querySelector(".cloud-2");
        const cloud3Element = document.querySelector(".cloud-3");
        if (cloud2Element && cloud3Element) {
            const newCloud2Height = cloud2Element.offsetHeight;
            const newCloud3Height = cloud3Element.offsetHeight;
            const totalHeight = newCloud3Height;
           
            setCloud2Height(newCloud2Height);
            setCloud3Height(newCloud3Height);
            setCloud4Margin(totalHeight); 
        }
    }

    return (
        <>
            <div className="cloud-1">
                <img src={cloud} alt="fits" className="fitsp-image" />
                <div className="first">
                    <div className="gradient"></div>
                    <div className="gradient2"></div>
                    <div className="text-1">
                        <p>Cloud <br />Engineering <br /> Services</p>
                    </div>
                </div>
            </div>

            <div className="cloud-2">
                <div className="text2">
                    <p>
                        At, DeepAlgorithms we architect and design Cloud Native Applications. Our solutions also help organizations effortlessly migrate their infrastructure needs to the cloud for better business outcomes and cost control. Our teams are well-versed in AWS, Azure and Google Cloud Technologies.
                    </p>
                </div>

                <div class="image-cloud">
                    <img src={pic} alt="Common" className="cloud-img" />
                </div>

                <div className="cloud-3">
                    <div className="cloud-card">
                        <img src={iconc} alt="vulnerability" className="vul_image" />
                        <h6 className="vul_text">Cloud Assessment</h6>

                        <p>Cloud readiness for adoption, your infrastructure and applications landscape, and provide recommendations and a transformation roadmap aligned to your business goals.</p>                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="fraud" className="fraud_image" />
                        <h6 className="fraud_text">Cloud Design & Architecture</h6>

                        <p>We architect a robust private/public cloud solution that is not only highly available but also highly flexible and scalable to manage your cloud operations.</p>                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="unlike" className="unlike_image" />
                        <h6 className="unlike_text">Cloud Native App Development</h6>

                        <p>We re-architect and rebuild the existing application to move to the Cloud to solve the existing application challenges in terms of performance, scalability, maintainability etc.</p>                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="cost" className="cost_image" />
                        <h6 className="cost_text">Cloud Infra, DevOps Engineering</h6>

                        <p>We implement capacity planning and help to select and implement the right cloud infrastructure setup with monitoring and logging that best suit the business ecosystem.</p>
                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="risks" className="risks_image" />
                        <h6 className="risks_text">Cloud Migration</h6>
                        <p>Our cloud migration experts bring non-disruptive and zero rollback migration capabilities while using industry-leading migration tools and methodologies.</p>
                    </div>

                    <div className="cloud-card">
                        <img src={iconc} alt="risks" className="risks_image" />
                        <h6 className="risks_text">Cloud Operation Management</h6>

                        <p>We manage cloud operations of both hardware and software with guaranteed SLAs to ensure that cloud operations are efficient in terms of using resources as required. </p>
                    </div>

                </div>


            </div>


            {/* <div className="cloud-3">
                    <div className="cloud-card">
                        <img src={iconc} alt="vulnerability" className="vul_image" />
                        <h6 className="vul_text">Increased Vulnerability</h6>

                        <p>Without behavioral biometrics, systems rely on static credentials like passwords, which are more susceptible to theft and brute-force attacks.</p>
                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="fraud" className="fraud_image" />
                        <h6 className="fraud_text">Limited Fraud Detection</h6>

                        <p>Lacks the ability to detect anomalies in user behavior, making it harder to identify fraudulent activity in real-time.</p>
                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="unlike" className="unlike_image" />
                        <h6 className="unlike_text">User Inconvenience</h6>

                        <p>Users may face more frequent security checks or password resets, leading to apoor experience.</p>
                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="cost" className="cost_image" />
                        <h6 className="cost_text">Higher Costs</h6>

                        <p>More reliance on traditional security measures can increase operational costs due to more frequent security breaches and the need for additional protective measures.</p>
                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="risks" className="risks_image" />
                        <h6 className="risks_text">Compliance Risks</h6>

                        <p>May fail to meet regulatory requirements that mandate strong authentication measures, leading to legal and financial repercussions.</p>
                    </div>
                    <div className="cloud-card">
                        <img src={iconc} alt="risks" className="risks_image" />
                        <h6 className="risks_text">Compliance Risks</h6>

                        <p>May fail to meet regulatory requirements that mandate strong authentication measures, leading to legal and financial repercussions.</p>
                    </div>
                </div> */}

            <div className="cloud-4" style={{ marginTop: `${cloud4Margin}px` }}>
                <img src={micro} alt="about" className="micro" />
                <div className="text-micro">
                    <h3>Micro Services</h3>
                    <p>
                        Microservices are loosely coupled applications that can be deployed and managed independently and knit together into a larger complex system. It enables the rapid development and delivery of large complex systems by using micro-services architecture patterns.
                        <br /> <br />
                        Each application function will be treated as an independent service that can be independently scaled/updated without affecting the rest of the application.
                    </p>
                </div>
            </div>

            <div className="cloud-5" >
                <div className="card-content">
                    <div className="text-container">
                        <h3>Mobile Apps</h3><br />
                        <p>Our team has deep experience in developing complex and customized Mobile Application Development. We provide innovative and cost-effective solutions to businesses.
                            <br /><br />The key goal of our mobile solutions is to get the optimal performance that drives the organization’s growth. We cover the entire app development cycle from concepts to development to deployment for our clients and helping them to accomplish their business goals. <br /> <br />
                            Our full-stack application development team has the rich industry expertise and in-depth technical knowledge to develop business-centric applications. </p>

                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Android App Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>iPhone App Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Hybrid App Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                        </ul>
                    </div>
                    <img src={mobile} alt="picture1" className="mobile-image" />
                </div>
            </div >

            {/* <div className="cloud-6" >
                <img src={ellipse} alt="triangle" />
                <div className="card-content">
                    <img src={web} alt="picture1" className="mobile-image" />
                    <div className="text-container">
                        <h3>Web Apps</h3><br />
                        <p>Our next-generation web application development team has deep experience in developing complex and customized Web Applications helping clients to accomplish their business goals.
                            <br /><br />Our full-stack application development team has the rich industry expertise and in-depth technical knowledge to develop business-centric applications. <br /> <br />
                            We adhere to a customer-oriented approach to provide you the best solutions that efficiently cover the business needs. We have successfully provided innovative and productive web application solutions to a mix of industry verticals. </p>

                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Enterprise web applications development</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>AngularJS UX/UI Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>PHP MySQL Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Cross-platform development</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                        </ul>
                    </div>
                </div>
            </div > */}


            <div className="cloud-6">
            
                <img src={ellipse} className="ellipse1" alt="triangle" />
                <img src={web} className="ellipse2" alt="triangle" />

                <div className="cloud-web">
                    <div className="text-container">
                        <h3>Web Apps</h3><br />
                        <p>Our next-generation web application development team has deep experience in developing complex and customized Web Applications helping clients to accomplish their business goals.
                            <br /><br />Our full-stack application development team has the rich industry expertise and in-depth technical knowledge to develop business-centric applications. <br /> <br />
                            We adhere to a customer-oriented approach to provide you the best solutions that efficiently cover the business needs. We have successfully provided innovative and productive web application solutions to a mix of industry verticals. </p>

                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Enterprise web applications development</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>AngularJS UX/UI Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>PHP MySQL Development Services</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Cross-platform development</h4>
                                    </div>
                                </div>
                            </li>
                            <br />
                        </ul>
                    </div>

                </div>
            </div>


        </>
    );
}

export default Cloud;