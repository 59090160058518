import React, { useRef, useEffect } from "react";
import './adaptive.css';
import adaptive from '../../../assets/adaptive.png'
import adaptive1 from '../../../assets/adaptive1.png'
import vulnerability from '../../../assets/vulnerability.png'
import fraud from '../../../assets/fraud.png'
import unlike from '../../../assets/unlike.png'
import cost from '../../../assets/cost.png'
import image from '../../../assets/image 22.png'
import union from '../../../assets/Union.png'
import solution from '../../../assets/solution.png'
import circle from '../../../assets/Ellipse 685.png'
import video from '../../../assets/video (4).png'
import flow from '../../../assets/imageb.png'
import benefits from '../../../assets/benefits.png'
import bank from '../../../assets/bank.png'
import video1 from '../../../assets/AdapID_Behaviourial_Identification (2).mp4'

function Adaptive() {

    const videoRef = useRef(null);

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function handleVideoVisibility() {
        const video = videoRef.current;

        if (video && isInViewport(video)) {
            video.muted = true;
            video.play().catch(error => {
                console.error("Failed to play:", error);
            });
        } else if (video) {
            video.pause();
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleVideoVisibility);
        return () => {
            window.removeEventListener('scroll', handleVideoVisibility);
        };
    }, []);


    return (
        <>
            <div className="adaptive_container">
                <div className="gradient"></div>
                <img src={adaptive} alt="Backgroundimage" />
                <div className="adaptive_text">
                    <p className="one">AI Adaptive Behavioral Authentication</p>
                    <p className="two">An advanced form of authentication that goes beyond traditional methods such as passwords.</p>
                </div>
            </div>

            <div className="adaptive_section1">
                <div className="section-card_adaptive">
                    <h3>What is AI Adaptive Behavioral Authentication ?</h3><br />
                    <p>
                        A security mechanism that uses various factors to verify the identity of a user. It is an advanced form of authentication that goes beyond traditional methods such as passwords. Adaptive authentication considers contextual information such as location, device, behavior, and risk level to determine if a user should be granted access or not.<br />
                        <br /> One important aspect of adaptive authentication is its ability to adapt to changing circumstances. By analyzing various factors such as location, device type, and user behavior, adaptive authentication can determine whether a login attempt is legitimate or not.
                    </p><br />
                </div>
                <img src={adaptive1} alt="about" className="responsive-image_adaptive" />
            </div>

            <div style={{ backgroundColor: '#F8F9FA', padding:'20px' }}>
                <div className="adaptive_section2">
                    <h3>What’s the need?</h3>
                    <p>The need for adaptive behavioral authentication arises from the limitations of traditional<br /> authentication methods and the evolving threat landscape.</p>
                </div>

                <div className="section-need">
                    <div className="card">
                        <img src={vulnerability} alt="vulnerability" className="vul_image" />
                        <h6 className="vul_text">Increased Vulnerability</h6>

                        <p>Without behavioral biometrics, systems rely on static credentials like passwords, which are more susceptible to theft and brute-force attacks.</p>
                    </div>
                    <div className="card">
                        <img src={fraud} alt="fraud" className="fraud_image" />
                        <h6 className="fraud_text">Limited Fraud Detection</h6>

                        <p>Lacks the ability to detect anomalies in user behavior, making it harder to identify fraudulent activity in real-time.</p>
                    </div>
                    <div className="card">
                        <img src={unlike} alt="unlike" className="unlike_image" />
                        <h6 className="unlike_text">User Inconvenience</h6>

                        <p>Users may face more frequent security checks or password resets, leading to apoor experience.</p>
                    </div>
                    <div className="card">
                        <img src={cost} alt="cost" className="cost_image" />
                        <h6 className="cost_text">Higher Costs</h6>

                        <p>More reliance on traditional security measures can increase operational costs due to more frequent security breaches and the need for additional protective measures.</p>
                    </div>
                    <div className="card" style={{ border: 'none', padding: '0' }}>

                        <img src={image} alt="image1" className="image_adaptive" />
                    </div>
                    <div className="card">
                        <img src={union} alt="risks" className="risks_image" />
                        <h6 className="risks_text">Compliance Risks</h6>

                        <p>May fail to meet regulatory requirements that mandate strong authentication measures, leading to legal and financial repercussions.</p>
                    </div>
                </div>

            </div>



            <div className="adaptive_section5">
                <div className="adap-gradient"></div>
                <div className="adaptive_s5">
                    <div className="text_adaptive">
                        <h3>Solution</h3>
                        <br />
                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Behavioral Biometrics</h4>
                                        <p>It's time to think beyond passwords with the help behavioral biometrics, adapID AI powered behavioral models provide you with 99.6% of accuracy. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Physiological biometrics</h4>
                                        <p>Make your authentication process more sophisticated by including physiological traits of the users for authentication.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>WebAuthn / FIDO2</h4>
                                        <p>adapID offers a seamless implementation of the WebAuthn / FIDO2 standard, allowing organizations to adopt WebAuthn effortlessly overnight, without the need for system development or data migration. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Device Binding</h4>
                                        <p>In the modern day and age knowing your user’s device is important for the your and your user’s safety. adapID uses state of the art device binding techniques to authenticate both users and their assets. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Zero Trust Authentication</h4>
                                        <p>It is a stringent security approach that demands rigorous identity verification for all users and devices seeking access to network resources, embodying the principle of "never trust, always verify." </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            {/* <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Efficient Resource Allocation</h4>
                                        <p>By focusing on behaviors that indicate risk, resources can be allocated more efficiently, focusing on actual threats rather than spreading efforts thinly over all user interactions.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Regulatory Alignment</h4>
                                        <p> Ensure that the adaptive authentication system meets or exceeds regulatory requirements for authentication and data protection. This may include implementing multi-factor authentication (MFA) where necessary and ensuring that behavioral data is handled in compliance with privacy regulations.</p>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <img src={solution} alt="solution" className="responsive-image" />
            </div>

            <div className="adaptive_section6">
                <h3>How it works ?</h3>
                <p style={{ marginTop: '1.5%' }}>Adaptive Behavioral Authentication in cybersecurity is a sophisticated method that dynamically.<br />
                    adjusts authentication requirements based on a user’s behavior and the context of their actions.</p>
            </div>

            <div className="section-videoa">
                <div className="content-container">
                    <div className="video-container" >
                        <video ref={videoRef}
                            poster={video}
                            controls
                            src={video1}
                        />
                    </div>
                </div>
            </div>

            <div className="adaptive_section8">
                <h3>Key components and processes</h3>
            </div>
            <div className="adaptive_section9">
                <img src={flow} alt="flow" className="adaptive_flow" />
            </div>

            <div className="adaptive_section10">
                <h3>Benefits</h3>
            </div>

            <div className="adaptive_section11">
                <div className="adaptive_benefits">
                    <ul>
                        <li>
                            <h4>Enhanced Security Posture</h4>
                            <p>Zero Trust Authentication ensures that trust is never assumed, reducing the risk of unauthorized access and data breaches by verifying every access request, regardless of origin. </p>
                        </li>
                        <br />
                        <li>
                            <h4>Strong User Authentication</h4>
                            <p>The combination of WebAuthn / FIDO2 and physiological biometrics provides a robust multi-factor authentication system that is both secure against phishing and convenient for users, leveraging something they have (a device) and something they are (biometric identifiers).</p>
                        </li>
                        <br />
                        <li>
                            <h4>Seamless User Experience</h4>
                            <p>Behavioral Biometrics enable background authentication by analyzing patterns in user behavior. </p>
                        </li>
                        <br />
                        <li>
                            <h4>Device Security</h4>
                            <p>Device Binding adds an extra layer of security by ensuring that the user's credentials are tied to a specific device, making stolen credentials useless on any other hardware. </p>
                        </li>
                        <br />
                        <li>
                            <h4>Compliance and Risk Reduction</h4>
                            <p>Adhering to the latest security standards like WebAuthn / FIDO2 not only helps in meeting regulatory compliance requirements but also minimizes the risk of financial and reputational damage associated with data breaches. </p>
                        </li>
                        <br />
                        <li>
                            <h4>Future-Proofing</h4>
                            <p>Leveraging advanced authentication methods prepares organizations for future security challenges by adopting an adaptable and forward-thinking security framework.</p>
                        </li>
                    </ul>
                </div>
                <img src={benefits} alt="benefits" className="benefits_image" />

            </div>


            <div className="ending">
                <h3>Use case in different industries</h3>
                <div className="adaptive_section12">

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Banking and Finance</h6>

                            <p>For securing transactions and detecting fraud.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Corporate Security</h6>

                            <p>Ensures that only authorized personnel access sensitive info.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Consumer Electronics</h6>

                            <p>Uses user behavior recognition for device security.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Healthcare</h6>

                            <p>Protects patient data and complies with regulations.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">E-commerce Platforms</h6>

                            <p>Safeguards user accounts and transactions.</p>
                        </div>
                    </div>

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Education Platforms</h6>

                            <p>Confirms identities in online learning environments.</p>
                        </div>
                    </div>
                </div>

            </div>

        </>

    );
}

export default Adaptive;