import React from "react";
import { useRef } from 'react';
import './continuous.css'
import monitor from '../../../assets/monitor.png'
import monitor1 from '../../../assets/monitor1.png'

import combat from '../../../assets/combat-icon.png'
import cyber from '../../../assets/cyber.png'
import cost from '../../../assets/high-cost-icon.png'
import finger from '../../../assets/unlike-icon.png'
import point from '../../../assets/Ellipsew.png'
import solutionc from '../../../assets/biometric.png'
import video from '../../../assets/video (6).png'
import benefits from '../../../assets/image 25.png'
import workflow from '../../../assets/workflow.png'
import techno from '../../../assets/techno.png'
import regular from '../../../assets/regular.png'
import releases from '../../../assets/new_releases.png'
import fitsp from '../../../assets/CM7 1.png'
import circle from '../../../assets/Ellipse 685.png'
import video1 from '../../../assets/Gif-2_2.gif'

function Continuous() {

    const videoRef = useRef(null);

    return (
        <>
            <div className="adaptive_container">
                <div className="gradient"></div>
                <img src={monitor} alt="Backgroundimage" />
                <div className="adaptive_text">
                    <p className="one">AI Continuous Authentication</p>
                    <p className="two">A systematic process to detect, understand, & mitigate cybersecurity threats and vulnerabilities in real-time.</p>
                </div>
            </div>

            <div className="adaptive_section1">
                <div className="section-card_adaptive">
                    <h3>What is AI Continuous Authentication & Monitoring?</h3><br />
                    <p>
                        adapID is a state-of-the-art authentication solution, perfectly tailored for the fintech sector, designed for both mobile and desktop platforms. It delivers continuous, real-time verification by leveraging behavioral biometrics, device analytics, and physiological biometrics, ensuring a dynamic and seamless security layer across all digital assets.
                        <br /><br />This system is specifically engineered to offer robust protection against Remote Access Trojans (RAT) and insider threats, securing users' digital transactions and sensitive data without disrupting the user experience. Easy to integrate and highly scalable, adapID enhances both security and convenience, providing a formidable defense against cyber threats in a modern enterprise environment, thereby safeguarding the financial ecosystem with unmatched efficiency.
                    </p>
                </div>
                <img src={monitor1} alt="about" className="responsive-image_pass"/>
            </div>

            <div className="ending" style={{ backgroundColor: '#F8F9FA' }}>
                <h3 style={{ color: 'black' }}>What's the need?</h3>
                <div className="adaptive_section12">
                    <div className="model">
                        {/* <div>
                            <img src={combat} alt="banking" className="bank_image" />
                        </div> */}<div className="border-con"></div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Cyber Threats</h6>
                            <p>With cyber-attacks like phishing and RATs increasing, financial institutions need advanced protection.</p>
                        </div>
                    </div>
                    <div className="model">
                        {/* <div>
                            <img src={cyber} alt="banking" className="bank_image" />
                        </div> */}<div className="border-con"></div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Remote Work</h6>
                            <p>The shift to remote work has broadened security vulnerabilities, necessitating continuous authentication across devices. </p>
                        </div>
                    </div>
                    <div className="model">
                        {/* <div>
                            <img src={cost} alt="banking" className="bank_image" />
                        </div> */}<div className="border-con"></div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Fintech Digitalization</h6>
                            <p>As fintech evolves with online services, ensuring secure yet frictionless user experiences becomes crucial. </p>
                        </div>
                    </div>
                    <div className="model">
                        {/* <div>
                            <img src={finger} alt="banking" className="bank_image" />
                        </div> */}<div className="border-con"></div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Regulatory Compliance</h6>
                            <p> Fintech must meet strict data protection regulations without adding operational burdens, demanding smarter security solutions.</p>
                        </div>
                    </div>
                    <div className="model">
                        {/* <div>
                            <img src={finger} alt="banking" className="bank_image" />
                        </div> */}<div className="border-con"></div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Consumer Expectations</h6>
                            <p>Users demand secure, seamless digital interactions, balancing strong security with convenience. </p>
                        </div>
                    </div>
                    <div className="model">
                        {/* <div>
                            <img src={finger} alt="banking" className="bank_image" />
                        </div> */}<div className="border-con"></div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Insider Threats</h6>
                            <p>Continuous monitoring and verification are key to addressing risks from within an organization. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="parent">
                <div className="cont_section5">
                    <div className="cont-gradient"></div>
                    <div className="cont_s5">
                        <div className="text_cont">
                            <h3>Solution</h3>
                            <p style={{ paddingTop: '10px' }}>Continuous authentication stands out as an essential solution to today's digital security challenges. </p>
                            <br />
                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>Behavioral Biometrics</h4>
                                            <p>Behavioral biometrics enhance continuous authentication by constantly analyzing user behaviors, such as navigating pattern, hand-eye coordination, typing patterns and other contextual factors, to ensure the person interacting with the system matches the authenticated user's unique profile. </p>
                                        </div>
                                    </div>
                                </li>
                                <br />
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>Endpoint analysis</h4>
                                            <p>Endpoint(asset) analysis aids in continuous authentication by evaluating device characteristics and usage patterns to confirm that access requests consistently come from the authenticated user's registered device, enhancing security and identity verification. </p>
                                        </div>
                                    </div>
                                </li>
                                <br />
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>Network Analysis </h4>
                                            <p>Network analysis contributes to continuous authentication by examining network behaviors and connection patterns, ensuring that access attempts are consistent with the authenticated user's typical network environments and usage, thereby adding an extra layer of security. </p>
                                        </div>
                                    </div>
                                </li>
                                <br />
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>Contextual factors analysis </h4>
                                            <p>Contextual factors such as geo-location, time analysis, and usage patterns bolster continuous authentication by aligning access requests with the user's normal behavior patterns, flagging any deviations for additional scrutiny or blocking, thus enhancing security. </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <img src={solutionc} alt="solution" className="solution" />
                </div>
                {/* <div className="sectionc">
                    <div className="content-container">
                        <div className="sectionc-text">
                            <h2>How it works ?</h2>
                            <p style={{ marginTop: '10px' }}>Continuous monitoring tool is a software solution that helps companies track and measure employee’s productivity. This tool uses automated data collection, analytics, and logs from applications, calendars, etc. </p>

                        </div>
                        <div className="video-container">
                            <video ref={videoRef}
                                poster={video}
                                controls
                                src={video}
                            />
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="sectioncv" >
                <div className="content-container">
                    <div className="content">
                        <h2>How it works ?</h2>
                        <p style={{ marginTop: '10px' }}>Continuous monitoring tool is a software solution that helps companies track and measure employee’s productivity. This tool uses automated data collection, analytics, and logs from applications, calendars, etc. </p>
                    </div>
                    <div className="video-container" >
                        {/* <video ref={videoRef}
                            poster={video}
                            controls
                            src={video1}
                        /> */}
                      
                        <img src={video1} className="gif"/>
                    </div>

                </div>
            </div>

            <h4 style={{ textAlign: 'center', marginTop:'40px', fontSize:'18px' }}> Benefits</h4>

            <div className="benefits">
                <img src={benefits} alt="release" className="responsive-imagec" />
                <div className="benefits-two">
                    <div className="cardc">
                        <div className="shape">
                            <img src={releases} alt="release" />
                        </div>
                        <div class="textb">
                            <p>Ensures only authorized user access</p>
                        </div>
                    </div>
                    <div className="cardc">
                        <div className="shape">
                            <img src={releases} alt="release" />
                        </div>
                        <div class="textb">
                            <p>Reduce risk of sensitive data exposer</p>
                        </div>
                    </div>
                    <div className="cardc">
                        <div className="shape">
                            <img src={releases} alt="release" />
                        </div>
                        <div class="textb">
                            <p>Provides insights into employee productivity</p>
                        </div>
                    </div>
                    <div className="cardc">
                        <div className="shape">
                            <img src={releases} alt="release" />
                        </div>
                        <div class="textb">
                            <p>Reveals application usage pattern for better resources</p>
                        </div>
                    </div>
                    <div className="cardc">
                        <div className="shape">
                            <img src={releases} alt="release" />
                        </div>
                        <div class="textb">
                            <p>Reduces unproductive website usage</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fitsc">
                <div className="initial">
                    <h4>Where it fits?</h4>
                    <p>Continuous authentication plays a pivotal role across various sectors,
                        ensuring heightened security and data protection. </p>

                    <div className="adaptive_sectionc">

                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">Finance and Banking</h6>


                            </div>
                        </div>
                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">E-commerce and Online Retail</h6>


                            </div>
                        </div>
                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">Healthcare</h6>


                            </div>
                        </div>
                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">Critical Infrastructure</h6>


                            </div>
                        </div>
                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">IT and Technology</h6>


                            </div>
                        </div>

                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">Telecommunications</h6>


                            </div>
                        </div>
                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">Government and Defense</h6>


                            </div>
                        </div>
                        <div className="modelc">
                            <div>
                                <img src={circle} alt="banking" className="bank_image" />
                            </div>
                            <div className="text-container-c">
                                <h6 className="bank_text">Education</h6>


                            </div>
                        </div>
                    </div>
                </div>
                <img src={fitsp} alt="fits" className="fitsp-image" />

            </div>


            {/* <div className="cardc">
                <div className="shape">
                  
                </div>
            </div> */}

            {/* <div style={{ backgroundColor: '#F8F9FA', padding: '20px' }}>
                <div className="adaptive_section2">
                    <h3>What’s the need?</h3>
                    <p style={{ marginTop: '1.5%' }}>The need for adaptive behavioral authentication arises from the limitations of traditional<br /> authentication methods and the evolving threat landscape.</p>
                </div>

                <div className="section-need">
                    <div className="card">
                        <img src={vulnerability} alt="vulnerability" className="vul_image" />
                        <h6 className="vul_text">Increased Vulnerability</h6>

                        <p>Without behavioral biometrics, systems rely on static credentials like passwords, which are more susceptible to theft and brute-force attacks.</p>
                    </div>
                    <div className="card">
                        <img src={fraud} alt="fraud" className="fraud_image" />
                        <h6 className="fraud_text">Limited Fraud Detection</h6>

                        <p>Lacks the ability to detect anomalies in user behavior, making it harder to identify fraudulent activity in real-time.</p>
                    </div>
                    <div className="card">
                        <img src={unlike} alt="unlike" className="unlike_image" />
                        <h6 className="unlike_text">User Inconvenience</h6>

                        <p>Users may face more frequent security checks or password resets, leading to apoor experience.</p>
                    </div>
                    <div className="card">
                        <img src={cost} alt="cost" className="cost_image" />
                        <h6 className="cost_text">Higher Costs</h6>

                        <p>More reliance on traditional security measures can increase operational costs due to more frequent security breaches and the need for additional protective measures.</p>
                    </div>
                    <div className="card" style={{ border: 'none', padding: '0' }}>

                        <img src={image} alt="image1" className="image_adaptive" />
                    </div>
                    <div className="card">
                        <img src={union} alt="risks" className="risks_image" />
                        <h6 className="risks_text">Compliance Risks</h6>

                        <p>May fail to meet regulatory requirements that mandate strong authentication measures, leading to legal and financial repercussions.</p>
                    </div>
                </div>

            </div>



            <div className="adaptive_section5">
                <div className="adaptive_s5">
                    <div className="text_adaptive">
                        <h3>Solution</h3>
                        <br />
                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Implement Behavioral  Biometrics</h4>
                                        <p >Use behavioral biometrics, such as keystroke dynamics, to continuously verify a user's identity. This adds a layer of security that is difficult to replicate or steal, unlike static credentials.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Continuous Authentication</h4>
                                        <p>Rather than relying solely on initial login credentials, employ continuous authentication that assesses the user's behavior throughout the session. This reduces the reliance on passwords and enhances security.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Real-time Behavioral Analysis</h4>
                                        <p>Deploy systems that analyze user behavior in real-time to detect anomalies.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Seamless User Experience</h4>
                                        <p>Ensure that the security checks are non-intrusive and do not interrupt the user experience. Adaptive authentication can reduce the frequency of overt security checks by relying on passive biometric verification.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Risk-Based Authentication</h4>
                                        <p>Adjust the authentication requirements based on the risk level of the action being attempted. For low-risk actions, simplify the authentication process; for high-risk actions, introduce additional verification steps.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Efficient Resource Allocation</h4>
                                        <p>By focusing on behaviors that indicate risk, resources can be allocated more efficiently, focusing on actual threats rather than spreading efforts thinly over all user interactions.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Regulatory Alignment</h4>
                                        <p> Ensure that the adaptive authentication system meets or exceeds regulatory requirements for authentication and data protection. This may include implementing multi-factor authentication (MFA) where necessary and ensuring that behavioral data is handled in compliance with privacy regulations.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <img src={solution} alt="solution" className="solution" />
            </div>

            <div className="adaptive_section6">
                <h3>How it works ?</h3>
                <p style={{ marginTop: '1.5%' }}>Adaptive Behavioral Authentication in cybersecurity is a sophisticated method that dynamically.<br />
                    adjusts authentication requirements based on a user’s behavior and the context of their actions.</p>
            </div>

            <div className="section-video">
                <div className="content-container">
                    <div className="video-container" >
                        <video ref={videoRef}
                            poster={video}
                            controls
                            src={video}
                        />
                    </div>
                </div>
            </div>

            <div className="adaptive_section8">
                <h3>Key components and processes</h3>
            </div>
            <div className="adaptive_section9">
                <img src={flow} alt="flow" className="adaptive_flow" />
            </div>

            <div className="adaptive_section10">
                <h3>Benefits</h3>
            </div>

            <div className="adaptive_section11">
                <div className="adaptive_benefits">
                    <ul>
                        <li>
                            <h4>Stronger MFA</h4>
                            <p>Enhances MFA by adding risk-based elements, providing robust defense against attacks like MFA bypass.</p>
                        </li>
                        <br />
                        <li>
                            <h4>Improved User Experience</h4>
                            <p>Offers simpler access under low-risk conditions, balancing security with convenience.</p>
                        </li>
                        <br />
                        <li>
                            <h4>Alignment with ZTA</h4>
                            <p>Fits well within the principles of Zero Trust, ensuring continuous verification.</p>
                        </li>
                        <br />
                        <li>
                            <h4>Enhanced Security</h4>
                            <p>These authentication methods provide an additional layer of protection against credential theft and phishing attacks, which are significant sources of cybercrime.</p>
                        </li>
                    </ul>
                </div>
                <img src={benefits} alt="benefits" className="benefits_image" />

            </div>


            <div className="ending">
                <h3>Use case in different industries</h3>
                <div className="adaptive_section12">

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Banking and Finance</h6>
                            
                            <p>For securing transactions and detecting fraud.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Corporate Security</h6>
                           
                            <p>Ensures that only authorized personnel access sensitive info.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Consumer Electronics</h6>
                          
                            <p>Uses user behavior recognition for device security.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Healthcare</h6>
                           
                            <p>Protects patient data and complies with regulations.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">E-commerce Platforms</h6>
                           
                            <p>Safeguards user accounts and transactions.</p>
                        </div>
                    </div>

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Education Platforms</h6>
                           
                            <p>Confirms identities in online learning environments.</p>
                        </div>
                    </div>
                </div>

            </div> */}

        </>

    );
}

export default Continuous;