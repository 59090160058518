import React, { useEffect, useState } from 'react';
import './sso.css'
import data from '../../../assets/data.png'
import data_image from '../../../assets/data_image.png'
import portrait from '../../../assets/portrait.png'
import databack from '../../../assets/databack.png'
import shield from '../../../assets/shield.png'
import image from '../../../assets/imageqq.png'
import bank from '../../../assets/bank.png'
import vulnerability from '../../../assets/vulnerability-icon.png'


function Dataleak() {

    return (
        <>
            <div className='dataleak_container'>
                <div className='gradv'></div>
                <div className='gradq'></div>
                <div className='right'>
                    <h4> SSO and Data Leak <br />Prevention ZTNA</h4>
                </div>
                <img src={data} alt='background' />
            </div>

            <div className="dataleak_pass">
                <div className="initial3">
                    <h4>What is ZTNA ?</h4>
                    <p>Zero Trust Network Access (ZTNA) is the technology that makes it possible to implement a Zero Trust security model.<br />
                        <br />"Zero Trust" is an IT security model that assumes threats are present both inside and outside a network. Consequently, Zero Trust requires strict verification for every user and every device before authorizing them to access internal resources.</p>
                </div>
                <img src={data_image} alt="newimage" />
            </div>

            <div className='dataleak_section1'>
                <div className='mainsection'>
                    <div className="initial4">
                        <h4>How it (ZTNA) helps in SSO and Data leak prevention?</h4><br />
                        <p>ZTNA improves SSO by providing robust, context-sensitive security for each access request, while also considerably strengthening DLP techniques by ensuring that sensitive data access is carefully regulated and monitored.<br />
                            <br /> Zero Trust Network Access (ZTNA) contributes significantly to Single Sign-On (SSO) and Data Leak Prevention (DLP) in the following ways:</p>
                    </div>
                    <div className="dataleakcloud">
                        <div className="dataleakcloud-card">
                            <div className='datacloud_heading'>
                                <h5 className="vul_text">ZTNA and Single sign-on (SSO)</h5>
                            </div>
                            <div className='datacloud_para'>
                                <h6>Enhanced Security with Simplified Access</h6>
                                <p>ZTNA, when used with SSO, simplifies user authentication while maintaining high security standards. Users authenticate once with SSO, and ZTNA continuously checks their trustworthiness before allowing access to specific resources.</p>
                                <div className="border-data"></div>
                                <h6>Reduced Credential Exposure</h6>
                                <p>SSO decreases the risk of credential compromise by eliminating the requirement for multiple logins. ZTNA assures that this convenience does not jeopardize security by meticulously checking every access request.</p>
                                <div className="border-data"></div>
                                <h6>Context-Aware Access</h6>
                                <p>ZTNA makes SSO context aware. It considers criteria such as user location, device health, and network security status before giving access, even if the user is verified via SSO.</p>
                                <div className="border-data"></div>
                                <h6>Adaptive Authentication</h6>
                                <p>In conjunction with SSO, ZTNA can implement adaptive authentication, which requires additional authentication procedures in questionable cases, thereby adding an extra layer of protection.</p>
                            </div>
                        </div>

                        <div className="dataleakcloud-card">
                            <div className='datacloud_heading'>
                                <h5 className="fraud_text">ZTNA and Data leak prevention (DLP)</h5>
                            </div>
                            <div className='datacloud_para'>
                                <h6>Least Privilege Access</h6>
                                <p>The least privilege principle of ZTNA guarantees that users have access to only the data they require, lowering the possibility of overly privileged accounts leaking data.</p>
                                <div className="border-data"></div>
                                <h6>Segmentation and Control</h6>
                                <p>ZTNA assists in providing data isolation by segmenting the network and limiting access to distinct segments. It restricts the amount of data that may be accessed even in the event of a breach.</p>
                                <div className="border-data"></div>
                                <h6>Real-time Monitoring and Response</h6>
                                <p>ZTNA requires continuous user activity monitoring. It is capable of instantly removing access or taking other corrective action in the event that it notices anomalous activity suggestive of a data leak.</p>
                                <div className="border-data"></div>
                                <h6>Secure Remote Access</h6>
                                <p>ZTNA reduces the possibility of data leaks through unprotected networks or endpoints by enabling secure remote access to organizational resources.</p>
                                <div className="border-data"></div>
                                <h6>Enforcing DLP Policies</h6>
                                <p>ZTNA can be integrated with DLP solutions to better enforce data handling policies and prevent unauthorized access to or transfer of sensitive data.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="datal_section2" >
                <div className="data-content">
                    <div className="data_text-container">
                        <h3>How it Can be used In Cyber Security?</h3><br />
                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={shield} alt="circle" width="30" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <p>Zero Trust Network Access (ZTNA) contributes to cybersecurity by enabling safe remote access to an organization's applications, data, and services through well-defined access control policies. </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={shield} alt="circle" width="30" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <p>ZTNA differs from standard VPNs in that it only grants access to certain applications, lowering the attack surface and the risk of unwanted access. It surrounds applications with an identity- and context-based logical access boundary, preventing discovery and restricting access to a set of identified entities via a trust broker. </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={shield} alt="circle" width="30" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <p>This technique boosts security by removing implicit trust, restricting network movement, and decreasing attack surfaces. ZTNA also works with Single Sign-On (SSO) providers to deliver a streamlined and secure user experience, removing the need for numerous passwords and lowering the risk of password-related security breaches. </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={shield} alt="circle" width="30" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <p>Overall, ZTNA improves an organization's security posture by enabling secure, direct access to specific resources, preventing data leaks, and lowering the danger of unwanted access.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <img src={databack} alt="model" className="responsive_model-image" />
            </div>

            <div className="before-remote">
                <h6>Where it can be used</h6>
                <p>ZTNA can be implemented in various specific places, such as enabling remote access, securing multicloud environments, replacing VPNs, and ensuring secure access to SaaS applications. ZTNA can be used in various specific places, not limited to industries</p>
            </div>

            <div className='dataleak_section3'>
                <img src={image} alt="release" className="responsive-image_dataleak1" />
                <div className="benefits-sso">
                    <div class="text-data">
                        <h5>Remote Access</h5>
                        <p>ZTNA is useful for enabling secure remote access to applications and services, regardless of where the user or resource is located.</p>
                    </div>
                    <div class="text-data">
                        <h5>Multi-cloud Environments</h5>
                        <p>It can be used to provide safe access to applications and services spread across several cloud environments.</p>
                    </div>
                    <div class="text-data">
                        <h5>VPN Replacement</h5>
                        <p>With the rise of remote work, ZTNA can be a more appropriate solution for widespread, distributed remote access, replacing traditional VPNs.</p>
                    </div>
                    <div class="text-data">
                        <h5>SaaS Applications</h5>
                        <p>ZTNA can assist protect access to certain Software as a Service (SaaS) applications by preventing overprovisioning of privileges and access, as well as lowering the risk of data breaches.</p>
                    </div>
                </div>
            </div>

            <div className="ending-sso" style={{ backgroundColor: '#F8F9FA' }}>
                <h3 style={{ color: 'black' }}>Benefits</h3>
                <div className="dataleak-bene">
                    <div className="model-datal">
                        <div>
                            <img src={vulnerability} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Improved Security</h6>
                            <p>ZTNA improves security by enclosing programs in a safe access boundary, limiting access via a trust broker to a set of identified entities, and eliminating implicit trust.</p>
                        </div>
                    </div>
                    <div className="model-datal">
                        <div>
                            <img src={vulnerability} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Reduced Risk of Data Breaches</h6>
                            <p>ZTNA lowers the risk of data breaches by enabling secure remote access to specified apps, hence minimizing the attack surface and preventing unauthorized access.</p>
                        </div>
                    </div>
                    <div className="model-datal">
                        <div>
                            <img src={vulnerability} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Increased Visibility</h6>
                            <p>ZTNA delivers real-time status and activity for all apps, enabling administrators to have improved control and visibility over network access.</p>
                        </div>
                    </div>
                    <div className="model-datal">
                        <div>
                            <img src={vulnerability} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Transparent User Experience</h6>
                            <p>ZTNA incorporates device compliance and health into access policies, providing users with a consistent and safe experience, removing the need for multiple passwords, and mitigating the risk .</p>
                        </div>
                    </div>
                    <div className="model-datal">
                        <div>
                            <img src={vulnerability} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Scalability</h6>
                            <p>ZTNA is network-agnostic, meaning it can function safely from any network, providing a smooth experience regardless of where the user is working.</p>
                        </div>
                    </div>

                    <div className="model-datal">
                        <div>
                            <img src={vulnerability} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Cost-Effective</h6>
                            <p>ZTNA systems can be less costly than standard VPNs, minimizing the need for hardware and maintenance.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="endingb">
                <h3>Use case in different industries</h3>
                <p className='heads'>ZTNA, or Zero Trust Network Access, is a security framework that can be applied to any industry that needs to securely connect users to applications and data. This means that ZTNA has the potential to be used by nearly all industries, as the need for secure remote access is universal.</p>
                <div className="adaptive_section12">
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Finance</h6>
                            <p>ZTNA can assist financial organizations protect sensitive client data by ensuring that only authorized individuals have access to financial apps.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Healthcare</h6>
                            <p>ZTNA can assist healthcare organizations protect patient data by ensuring that only authorized medical personnel have access to patient records.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Retail</h6>
                            <p>Retailers must protect their customers' data and intellectual property, and ZTNA can assist in ensuring that only authorized individuals have access to critical information.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Technology</h6>
                            <p>Technology firms frequently have a distributed workforce, and ZTNA may assist in securely connecting employees to apps and data wherever they are.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Government</h6>
                            <p>Government agencies must protect sensitive data, and ZTNA can assist ensure that only authorized individuals can access government networks.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dataleak;
