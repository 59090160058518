import React from "react";
import './about.css'
import robot from '../../assets/robotic.png'
import about from '../../assets/image 62.png'
import startup from '../../assets/image 68.png'
import iit from '../../assets/CCoE-logo.png'
import iitm from '../../assets/iitm.png'
import one from '../../assets/jpmishra.png'
import two from '../../assets/two.png'
import three from '../../assets/three.png'
import four from '../../assets/image4.png'
import five from '../../assets/image5.png'
import link from '../../assets/linkedin.png'
import line from '../../assets/Rectangle 3204.png'
import adapid from '../../assets/image 1 (2).png'

function About() {
  return (
    <>
      <div className="about_container">
        <div className="about_gradient-data"></div>
        <img src={robot} alt="Backgroundimage" style={{ width: '70%' }} />
        <div className="second">
          <img src={line} alt="line" className="line-image" style={{ height: '8.5vw' }} />
          <img src={adapid} alt="line" className="adapid-image" />
          <p className="para1">Recognize people by the way they type and swipe</p>
          <p className="para2">Fostering AI. Connecting Minds.</p>
        </div>


      </div>







      <div className="about_section1">
        <img src={about} alt="about" className="responsive-image_about" />
        <div className="section-card_about">
          <h3>COMPANY</h3><br />
          <h4>DeepAlgorithms Empowers Digital <br />Financial Experiences that People Trust</h4>
          <p>
            We are pioneers of behavioral biometrics, cutting-edge technology leveraging the <br />power of human digital behavior to deliver actionable insights and establish a <br />world centered on trust and ease.<br /><br />Today, financial institutions around the globe are using DeepAlgorithms to more <br />effectively fight fraud, drive digital transformation and accelerate business <br />growth.
          </p><br />
        </div>
      </div>

      <div className="about_section2">
        <div className="section-card_about1">
          <div className="overview">
            <h3>Overview</h3><br />
            <p>DeepAlgorithms is a global leader in next-generation digital services and consulting. We enable clients around the globe to serve towards digital transformation.</p>
          </div>
        </div>
        <div className="section-card_about2">
          <p>adapID Solutions stands at the forefront of the cybersecurity industry, offering innovative products that utilize behavioral biometrics and leading-edge technology to combat cyber threats. Their advanced security solutions are designed to provide robust, continuous authentication by analyzing unique user behaviors, ensuring protection against unauthorized access and cyber-attacks. By integrating the latest in artificial intelligence (AI), cloud technologies, and data analytics, adapID Solutions offers a holistic approach to digital security that meets the diverse needs of modern businesses.<br />
            <br />In addition to its cutting-edge products, adapID Solutions offers a comprehensive range of services, including AI and cloud security consulting, data analytics insights, penetration testing, and compliance advisory. These services aim to equip organizations with the necessary tools and strategies to enhance their security posture and navigate the complexities of cybersecurity and digital transformation. As a trusted partner in cybersecurity, adapID Solutions is dedicated to helping businesses secure their digital assets and thrive in an increasingly connected world. </p>
        </div>
      </div>

      <div className="about-3">
        <div className="about_need">
          <div className="about_card">
            <h6>Inception</h6>
            <p>Founded in 2018, Presence in India and United States</p>
          </div>
          <div className="about_card">
            <h6>Expertise</h6>
            <p>Cyber Security, Artificial Intelligence, Cloud Engineering, Enterprise App Development</p>
          </div>
          <div className="about_card">
            <h6>Solutions</h6>
            <p>Deliver intelligent, cutting - edge technology solutions that empower businesses.</p>
          </div>
          <div className="about_card">
            <h6>Accomplishment</h6>
            <p>Successfully Serving clients around the globe - Seizure Tracker, Cognitive Zen</p>
          </div>
        </div>
      </div>

      <div className="about_section3">
        <h3>DeepAlgorithms is incubated at</h3>
      </div>

      {/* <div className="about_section4">
        <div className="first_image">
          <img src={startup} alt="startup" />
        </div>
        <div className="second_image">
          <img src={iit} alt="cyber" />
        </div>
      </div> */}


      <div className="incubated">
        <div className="incubated-card">
          <img src={startup} alt="startup" />
        </div>
        <div className="incubated-card1">
          <img src={iitm} alt="cyber" />
        </div>

        <div className="incubated-card">
          <img src={iit} alt="cyber" />
        </div>
      </div>

      {/* <div className="incubated-card1">
        <img src={iitm} alt="startup" />
      </div> */}


      <div className="about_container1">
        <h4>Meet the Leadership Team</h4>
        <div className="about_need1">
          <div className="about_card1">
            <div className="one">
              <img src={one} alt="onecard" className="one_image" />
            </div>
            <div class="flex-container">
              <h5 className="one_text">JP Mishra</h5>
              <a href="https://www.linkedin.com/in/j-p-mishra-97272011/"><img src={link} alt="link" width="23.5px" className="link_Image" /></a>
            </div>
            <p className="head">Founder and CEO</p>
            <div className="one_p">
              <p>Executive Business Analytics <br />IIM Kolkata, PGDESD-CDAC</p>
            </div>
          </div>
          <div className="about_card1">
            <div className="two">
              <img src={two} alt="twocard" className="two_image" />
            </div>
            <div class="flex-container">
              <h5 className="one_text">Dr. Amit Shukla</h5>
              <a href="https://www.linkedin.com/in/amit-shukla-11a0391a/"><img src={link} alt="link" width="23.5px" className="link_Image" /></a>
            </div>
            <p className="head">Co-founder and CTO</p>
            <div className="one_p">
              <p>B.Tech – IIT Kanpur, Phd  <br />Imperial College London</p>
            </div>
          </div>
        </div>

        <div className="about_need2">
          <div className="about_card2">
            <div className="three">
              <img src={three} alt="threecard" className="three_image" />
            </div>
            <div class="flex-container1">
              <h5 className="one_text">David Smith</h5>
              <a href="https://www.linkedin.com/in/desmith/"><img src={link} alt="link" width="23.5px" className="link_Image" /></a>
            </div>
            <p className="head">SVP, Technology Engineering</p>
            <div className="one_p1">
              <p>Washington - DC</p>
            </div>
          </div>

          <div className="about_card2">
            <div className="four">
              <img src={four} alt="fourcard" className="four_image" />
            </div>
            <div class="flex-container1">
              <h5 className="one_text">Ajitesh Shukla</h5>
              <a href="https://www.linkedin.com/in/ajitesh/"><img src={link} alt="link" width="23.5px" className="link_Image" /></a>
            </div>
            <p className="head">Mentor & Advisory Board Member</p>
            <div className="one_p1">
              <p >B.Tech - IIT Kharagpur</p>
            </div>
          </div>
          <div className="about_card2">
            <div className="five">
              <img src={five} alt="fivecard" className="five_image" />
            </div>
            <div class="flex-container1">
              <h5 className="one_text">Mr. Amit Shukla</h5>
              <a href="https://www.linkedin.com/in/amit-shukla-b7023a3/"><img src={link} alt="link" width="23.5px" className="link_Image" /></a>
            </div>
            <p className="head">Business Mentor and Advisory Board Member
              Founder and MD, EasyGov (Jio Platform)</p>
            {/* <div className="one_p">
              <p>B.Tech – IIT Kanpur, Phd <br />University of Maryland, USA</p>
            </div> */}
          </div>
        </div>
      </div>





    </>
  );
}

export default About;
