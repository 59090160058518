import React from "react";
import './penetration.css';
import pene from '../../../assets/pene.png'
import picture from '../../../assets/image 28.png'
import card1 from '../../../assets/1.png'
import card2 from '../../../assets/2.png'
import card3 from '../../../assets/3.png'
import card4 from '../../../assets/4.png'
import card5 from '../../../assets/5.png'
import arrow1 from '../../../assets/arrow1.png'
import arrow2 from '../../../assets/arrow2.png'
import arrow3 from '../../../assets/arrow3.png'
import fingerprint from '../../../assets/fingerprint.png'
import ellipse1 from '../../../assets/Ellipse 694.png'
import ellipse2 from '../../../assets/Ellipse 695.png'
import ellipse3 from '../../../assets/Ellipse 696.png'
import peneend from '../../../assets/Group 20.png'

function Penetration() {



    return (
        <>
            <div className="pene_container">
                <div className="gradient-pene"></div>
                <img src={pene} alt="Backgroundimage" />
                <div className="pene_text">
                    <p className="one">Penetration Testing</p>
                </div>
            </div>

            <div className="pene_section1">
                <div className="section-card_pene">
                    <h3>Definition</h3><br />
                    <p>
                        A penetration test (pen test) is an authorized simulated attack performed on a computer system to evaluate its security. Penetration testers use the same tools, techniques, and processes as attackers to find and demonstrate the business impacts of weaknesses in a system.<br /><br />
                        Penetration tests usually simulate a variety of attacks that could threaten a business. They can examine whether a system is robust enough to withstand attacks from authenticated and unauthenticated positions, as well as a range of system roles. With the right scope, a pen test can dive into any aspect of a system.
                    </p>
                </div>
                <img src={picture} alt="about" className="responsive-image_pene" />
            </div>

            {/* <div className="phases">
                <p className="para1">5 phases of Pen Test</p>
                <div className="main">
                    <div className="card1">
                        <img src={card1} alt="one" />
                        <h4>Reconnaissance</h4>
                        <p>Lorem ipsum dolor sit amet, consect etur adipiscing elit. Proin consectetur nec justo sed pellentesque. </p>
                    </div>
                    <div className="arrow1">
                        <img src={arrow1} alt="a" />
                    </div>
                    <div className="card2">
                        <img src={card1} alt="one" />
                        <h4>Reconnaissance</h4>
                    </div>
                </div>
            </div> */}

            <div className="phases">
                <p className="para1">5 phases of Pen Test</p>
                <div class="wrapper">
                    <div class="svg-container">
                        <svg version="1.1" viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet" class="svg-content">
                            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                                <feOffset in="SourceAlpha" dx="0" dy="0" result="offset" />
                                <feGaussianBlur in="offset" stdDeviation="0.8" result="blur" />
                                <feBlend in="SourceGraphic" in2="blur" mode="normal" />
                            </filter>
                            <g class="box-group">

                                <g transform="translate(80)">
                                    <rect fill="white" x="2" y="25" rx="2" ry="2" width="100" height="80" filter="url(#shadow)" />
                                    <g transform="translate(40,30)">
                                        <image xlinkHref={card1} width="15" height="15" />
                                    </g>
                                    <text x="18" y="58" fill="black" font-size="8px">
                                        <tspan x="22" dy="0">Reconnaissance</tspan>
                                        <tspan x="15" dy="12" font-size="6px">The goal is to gather as much </tspan>
                                        <tspan x="15" dy="6" font-size="6px">data as possible so that the</tspan>
                                        <tspan x="15" dy="6" font-size="6px">tester can plan an effective</tspan>
                                        <tspan x="15" dy="6" font-size="6px">attack strategy.</tspan>
                                    </text>
                                </g>

                                {/* <g transform="translate(165,60)">
                                    <image xlinkHref={arrow1} width="30" height="30" />
                                </g> */}

                                <g transform="translate(202,35)">
                                    <rect fill="white" x="2" y="25" rx="2" ry="2" width="100" height="80" filter="url(#shadow)" />
                                    <g transform="translate(40,30)">
                                        <image xlinkHref={card2} width="15" height="15" />
                                    </g>
                                    <text x="18" y="58" fill="black" font-size="8px">
                                        <tspan x="32" dy="0">Scanning </tspan>
                                        <tspan x="15" dy="12" font-size="6px">The tester uses various tools</tspan>
                                        <tspan x="15" dy="6" font-size="6px">to identify open ports and</tspan>
                                        <tspan x="15" dy="6" font-size="6px">check network traffic on the</tspan>
                                        <tspan x="15" dy="6" font-size="6px">target system.</tspan>

                                    </text>
                                </g>

                                <g transform="translate(182,60)">
                                    <image xlinkHref={arrow1} width="30" height="40" />
                                </g>

                                <g transform="translate(324,70)">
                                    <rect fill="white" x="2" y="25" rx="2" ry="2" width="100" height="80" filter="url(#shadow)" />
                                    <g transform="translate(40,30)">
                                        <image xlinkHref={card3} width="15" height="15" />
                                    </g>
                                    <text x="18" y="58" fill="black" font-size="8px">
                                        <tspan x="28" dy="0">Vulnerability</tspan>
                                        <tspan x="28" dy="8">Assessment</tspan>
                                        <tspan x="14" dy="12" font-size="6px">All the data gathered is to</tspan>
                                        <tspan x="14" dy="6" font-size="6px">identify potential vulnerabilities</tspan>
                                        <tspan x="14" dy="6" font-size="6px">and determine whether they </tspan>
                                        <tspan x="14" dy="6" font-size="6px">can be exploited.</tspan>
                                    </text>
                                </g>

                                <g transform="translate(304,100)">
                                    <image xlinkHref={arrow1} width="30" height="30" />
                                </g>
                            </g>

                           


                            <g class="box-group" transform="translate(0,165)">

                                <g transform="translate(138, -30)">
                                    <rect fill="white" x="2" y="25" rx="2" ry="2" width="100" height="80" filter="url(#shadow)" />
                                    <g transform="translate(44,30)">
                                        <image xlinkHref={card5} width="15" height="15" />
                                    </g>
                                    <text x="18" y="58" fill="black" font-size="8px">
                                        <tspan x="35" dy="0">Reporting</tspan>
                                        <tspan x="16" dy="12" font-size="6px">The tester prepares a report</tspan>
                                        <tspan x="16" dy="6" font-size="6px">documenting the penetration</tspan>
                                        <tspan x="16" dy="6" font-size="6px">test’s findings.</tspan>
                                    </text>
                                </g>

                                

                                <g transform="translate(260,7)">
                                    <rect fill="white" x="2" y="25" rx="2" ry="2" width="100" height="80" filter="url(#shadow)" />
                                    <g transform="translate(42,30)">
                                        <image xlinkHref={card4} width="20" height="20" />
                                    </g>
                                    <text x="18" y="58" fill="black" font-size="8px">
                                        <tspan x="35" dy="4">Exploitation</tspan>
                                        <tspan x="16" dy="12" font-size="6px">The tester attempts to access</tspan>
                                        <tspan x="16" dy="6" font-size="6px">the target system and exploit</tspan>
                                        <tspan x="16" dy="6" font-size="6px">the identified vulnerabilities</tspan>
                                        <tspan x="16" dy="6" font-size="6px">using a tool like Metasploit.</tspan>
                                    </text>
                                </g>

                                <g transform="translate(232,35)">
                                    <image xlinkHref={arrow3} width="30" height="30" />
                                </g>
                            </g>

                            <g transform="translate(320,175)">
                                <image xlinkHref={arrow2} width="30" height="30" />
                            </g>
                            
                        </svg>
                    </div>
                </div>
            </div>

            <div className="pene-ending" >

                <img src={ellipse1} className="ellipse1" alt="triangle" />
                <img src={ellipse2} className="ellipse2" alt="triangle" />
                <img src={ellipse3} className="ellipse3" alt="triangle" />

                <p className="pene-para">Penetration testing allows an organization to perform</p>

                <div className="adaptive_sectionpe">

                    <div className="modelpe">
                        <div>
                            <img src={peneend} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Security assessment of newly installed products or patches</h6>


                        </div>
                    </div>
                    <div className="modelpe">
                        <div>
                            <img src={peneend} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Validation of business continuity programs</h6>


                        </div>
                    </div>
                    <div className="modelpe">
                        <div>
                            <img src={peneend} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Risk validation for a business merger or acquisition</h6>


                        </div>
                    </div>
                    <div className="modelpe">
                        <div>
                            <img src={peneend} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Due Diligence and required protection of end-user data</h6>


                        </div>
                    </div>
                    <div className="modelpe">
                        <div>
                            <img src={peneend} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Regulatory compliance check</h6>


                        </div>
                    </div>

                    <div className="modelpe">
                        <div>
                            <img src={peneend} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Implement the missing security policy if any gaps were discovered.</h6>


                        </div>
                    </div>
                    
                </div>

            </div>

        </>

    );
}

export default Penetration;