import React from "react";
import './chatbot.css';
import banner from '../../../assets/human-big-data-visualization 1.png'
import picture2 from '../../../assets/image 41.png'
import circle from '../../../assets/Ellipse 685.png'
import cctv from '../../../assets/image 30 (2).png'
import point from '../../../assets/Ellipsew.png'
import endpic from '../../../assets/image 46.png'
import drone from '../../../assets/image 47.png'
import icon1 from '../../../assets/Vector (2).png'


function Chatbot() {


    return (
        <>
            <div className="section1-chat">
                <div className="first">
                    <div className="chat-overlay">
                        <p className="one">Artificial</p>
                        <p className="two">Intelligence</p>
                    </div>
                </div>
                <div className="gradient-chat"></div>
                <img src={banner} alt="solution" className="responsive-image" />
            </div>

            <div className="chat-ind">
                <div className="common-imaged">
                    <img src={picture2} alt="Imagew" />
                </div>
                <div className="chat-head">
                    <p className="head1">CONVERSATIONAL</p>
                    <p className="head2">AI Chatbots</p>
                </div>
                <div className="top">
                    <div className="content">
                        <div className="sectiont">
                            <h5>Enhance Customer Experience and Engagement with AI powered Conversational Chatbot.</h5>
                            <p>In today’s day and age when customers want 24/7 service and information, our friendly Chatbot can help.<br /><br />
                                At Deep Algorithms, we build AI enabled intelligent bots for your business needs. Our Chatbots not only help save time and resources by automating monotonous tasks but also help in increasing a company’s productivity.
                                <br /><br />Using the proprietary advanced NLP and Machine Learning Capabilities we provide end to end Chatbot related Offerings to our Customers.</p>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="content">
                        <div className="sectionld">

                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Enriched Communication with Customers</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Enhanced Customer Experience</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Increased Customer Engagement</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Reduced Costs with Automation</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Instant Response to Customer Queries 24/ 7</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="chat-cctv">
                <div className="gradient-cctv"></div>
                <div className="left">

                    <p className="one">AI ENABLED</p>
                    <p className="two">CCTV Surveillance</p>
                    <p className="three">Fully Automated Surveillance and Threat Detection</p>
                    <p className="four">Deep Algorithms’ Artificial Intelligence Software enabled Surveillance Cameras help detect potential problems. Offering Advanced Protection for your Commercial and Residential Spaces 24/7 with zero room for any human error.<br />
                        <br />   AI powered video surveillance can detect and report any deviation instantly, thus making for an efficient security system. Real time alerts, 24/7 make it far more superior to non-AI video Surveillance.</p>
                    <p className="five">Smarter Surveillance</p>
                    <div className="cctv-list">
                        <div>
                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Remote Asset </p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Property Monitoring</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Flame Detection</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Smoke Detection</p>
                                        </div>
                                    </div>
                                </li>



                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Periphery Detection</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Intrusion Detection</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <p>Traffic Surveillance</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src={cctv} alt="cctv" className="cctv-image" />
            </div>


            <div className="chat-ind1">
                <div className="common-imaged">
                    <img src={endpic} alt="Imagew" />
                </div>
                <div className="last"></div>
                <div className="chat-head">
                    <p className="head1">Alexa</p>
                    <p className="head2">Skill Development Services</p>
                </div>
                <div className="bottom">
                    <div className="content">
                        <div className="sectionld">

                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Alexa Skill Development and Customization for Business</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>End to end testing of Alexa Skills</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Voice Conversation Design for Alexa Skills</p>
                                </div>
                            </div>
                            <div className="modelchat">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Integration with Mobile App</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top">
                    <div className="content">
                        <div className="sectiont">
                            <p>The ability of Alexa App to offer an interactive, personalized and customized experience, is the foremost feature that businesses can leverage to voice their brand as well as enhance customer experience.<br /><br />
                            At Deep Algorithms, we can develop customized Alexa Skill for your business, allowing your customers to use their voice to access your services, news and content, for an unmatched user experience.
                                </p>
                        </div>
                    </div>
                </div>

                <div className="last"></div>

            </div >

            <div className="chat-drone">
                <div className="drone-main">
                    <div className="gradient"></div>
                    <div className="right">
                        <div className="text-containerd">
                            <p className="one">Drone Aerial</p>
                            <p className="two">Surveillance System</p>
                            <p className="three">Autonomous Drone Surveillance, Tracking and Monitoring System</p>
                            <p className="four">Helps to detect and manage threats, survey larger areas in shorter time and respond quickly to intruders.<br /><br />
                                Our Aerial Surveillance System can monitor, track objects, places and people using its sensors. Drones can also penetrate through small and confined spaces and provide a Bird’s eye view of large and remote areas quickly.</p>
                        </div>
                    </div>
                    <div className="left">
                        <img src={drone} alt="picture1" />
                    </div>
                </div>
                <div className="drone-cards">
                    <div className="drone-card">
                        <img src={icon1} alt="vulnerability" width="80" />
                        <p>360° Remote Aerial Surveillance</p>
                    </div>
                    <div className="drone-card">
                        <img src={icon1} alt="fraud" width="80" />
                        <p>Real time Alert System</p>
                    </div>
                    <div className="drone-card">
                        <img src={icon1} alt="unlike" width="80" />
                        <p>Real-time detection</p>
                    </div>
                    <div className="drone-card">
                        <img src={icon1} alt="cost" width="80" />
                        <p>Real-time tracking and monitoring.</p>
                    </div>
                    <div className="drone-card">
                        <img src={icon1} alt="risks" width="80" />
                        <p>Provides accurate Data</p>
                    </div>
                </div>
            </div>



        </>

    );
}

export default Chatbot;