import React from "react";
import './cctv.css';
import banner from '../../../assets/image 30 (1).png'
import icon from '../../../assets/cctv-icon.png'
import picture1 from '../../../assets/image 42.png'

function Cctv() {


    return (
        <>
            <div className="cctv-1">
            <img src={banner} alt="fits" className="fitsp-image" />
                <div className="first">
                    <p>AI enabled <br />CCTV Surveillance</p>
                </div>
                
            </div>


            <div>
                <p>Smarter Surveillance</p>
                <div style={{ padding: '20px' }}>

                    <div className="section2-cctv">
                        <div className="card-cctv">
                            <img src={icon} alt="cctv" width='40' />
                            <p >Remote Asset</p>

                        </div>
                        <div className="card-cctv">
                            <img src={icon} alt="cctv" width='40' />
                            <p>Property Monitoring</p>

                        </div>
                        <div className="card-cctv">
                            <img src={icon} alt="cctv" width='40' />
                            <p>Flame Detection</p>
                        </div>
                    </div>

                </div>
            </div>


            <div>
                <div >

                    <div className="section3-cctv">
                        <div className="card-cctv1">
                            <img src={icon} alt="cctv" width='40' />
                            <p >Smoke Detection</p>

                        </div>
                        <div className="card-cctv1">
                            <img src={icon} alt="cctv" width='40' />
                            <p>Periphery Detection</p>

                        </div>
                        <div className="card-cctv1">
                            <img src={icon} alt="cctv" width='40' />
                            <p>Intrusion Detection</p>
                        </div>
                        <div className="card-cctv1">
                            <img src={icon} alt="cctv" width='40' />
                            <p>Traffic Surveillance</p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section4-cctv">
                <div className="initial">
                    <h4>Fully Automated Surveillance
                        and Threat Detection</h4>
                    <p>Deep Algorithms’ Artificial Intelligence Software enabled Surveillance Cameras help detect potential problems. This enables an immediate response to neutralize the threat.</p>
                    <p>Our Intelligent CCTV Surveillance offers Advanced Protection for your Commercial and Residential Spaces 24/7.</p>
                    <p>The accuracy of AI surveillance is far more than traditional systems, as there is no room for any human error.</p>
                    <p>AI powered video surveillance can detect and report any deviation instantly, thus making for an efficient security system. Real time alerts, 24/7 make it far more superior to non-AI video Surveillance.</p>
                </div>
                <div className="image-container">
                    <img src={picture1} alt="fits" className="fitsp-image" />
                    <div className="gradient-overlay"></div>
                </div>
            </div>

        </>

    );
}

export default Cctv;