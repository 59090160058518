import React from 'react';
import './productivity.css'
import banner from '../../../assets/PA1 1.png'
import prod from '../../../assets/image 78.png'
import uses from '../../../assets/image 80.png'
import common from '../../../assets/image 79.png'
import circle from '../../../assets/Ellipse 685.png'
import common1 from '../../../assets/image 81.png'

function Productivity() {

    return (
        <>
            <div className="adaptive_container">
                <div className="gradient"></div>
                <img src={banner} alt="Backgroundimage" />
                <div className="adaptive_text" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <p className="one">Productivity Monitoring Solution</p>
                </div>
            </div>

            <div className="password_section1">
                <img src={prod} alt="about" className="responsive-image_password" />
                <div className="section-card_password">
                    <h3>What is Productivity monitoring Solution ?</h3><br />
                    <p>Productivity monitoring is the practice of measuring and analyzing employees' work activities and output, generally through the use of software or tools, in order to measure their efficiency and productivity.
                        <br /><br />  This method entails measuring many aspects of work performance, such as task completion time, output quality and quantity, and deadline adherence.
                        <br /><br />  The goal is to understand how effectively and efficiently personnel operate, identify areas for growth, drive training and development efforts, and make the best use of resources.
                    </p>
                </div>
            </div>

            <div className="prod-uses">
                <div className="initial">
                    <h4>How can it be used in cyber security?</h4>
                    <p>Productivity monitoring can help improve cybersecurity by ensuring that employee activity on company networks is authentic. Employers can track computer activity to check for high-risk conduct, verify genuine use of business resources, and prevent access to phishing sites and illegitimate programs.</p>
                    <p>Additionally, employee monitoring software can assist improve data security, avoid data breaches, and guarantee that employees use company networks properly.</p>
                    <p>Time-tracking systems can improve workplace security and transparency by revealing hidden costs, underperforming personnel, and productivity killers.</p>
                    <p>Overall, productivity monitoring can help to improve cybersecurity and protect an organization's critical information.</p>
                </div>
                <div className='gradientu'></div>
                <img src={uses} alt="fits" className="fitsp-image" />

            </div>

            <div className="prod-use1">
                <div className="common-imagep">
                    <img src={common} alt="Imagew" />
                </div>
                <div className="top">
                    <div className="content">
                        <div className="sectiont">
                            <h5>Where can we use it?</h5>
                            <p>Productivity monitoring can be applied in various contexts to enhance security, ensure compliance, and mitigate insider threats.</p>
                            <div className="modelprod">
                                <img src={circle} alt="banking" className="bank_image" />
                                <h6 className="bank_text">Enterprise Security</h6>
                            </div>
                            <div className="modelprod">
                                <img src={circle} alt="banking" className="bank_image" />
                                <h6 className="bank_text">Remote Workforce Management</h6>
                            </div>
                            <div className="modelprod">
                                <img src={circle} alt="banking" className="bank_image" />
                                <h6 className="bank_text">Data Loss Prevention</h6>
                            </div>
                            <div className="modelprod">
                                <img src={circle} alt="banking" className="bank_image" />
                                <h6 className="bank_text">Compliance and Regulatory Requirements</h6>
                            </div>
                            <div className="modelprod">
                                <img src={circle} alt="banking" className="bank_image" />
                                <h6 className="bank_text">Insider Threat Detection</h6>
                            </div>
                            <div className="modelprod">
                                <img src={circle} alt="banking" className="bank_image" />
                                <h6 className="bank_text">Network Security</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="content">
                        <div className="sectionld"></div>
                    </div>
                </div>
            </div >

            <div className="biometrics-bene">
                <div className='head'>
                    <h3 style={{ color: 'black' }}>Benefits</h3>
                    <p>Productivity monitoring has numerous advantages, including greater productivity, improved security,<br />
                        insider threat prevention, and compliance with regulatory obligations.</p>
                </div>
                <div className="bio-bene">

                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Increased Productivity</h6>
                            <p>Monitoring employee activities can lead to a 7% increase in productivity, as they tend to stay focused on their tasks and minimize time-wasting activities when aware.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Enhanced Security</h6>
                            <p>Productivity monitoring can help organizations detect and prevent data breaches, unauthorized access, and data leaks, thereby enhancing overall security.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Insider Threat Prevention</h6>
                            <p>By tracking user activity, organizations can mitigate insider threats and prevent the unauthorized sharing of sensitive information.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Compliance with Legal Requirements</h6>
                            <p>Productivity monitoring can assist orgs in ensuring compliance with legal and regulatory requirements, particularly in industries with strict compliance regulations</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Employee Engagement and Performance Evaluation</h6>
                            <p>It can contribute to improved employee engagement, performance evaluation, and the identification of leaders and outsiders within departments.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Resource Optimization</h6>
                            <p>Monitoring employee activity can help in optimizing resources by identifying and addressing unproductive activities and excessive use of unproductive software.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="prod-ind">
                <div className="common-imaged">
                    <img src={common1} alt="Imagew" />
                </div>
                <div className="top">
                    <div className="content">
                        <div className="sectiont">
                            <h5>Which Industries can use it?</h5>
                            <p>Productivity monitoring can be used in various industries, including finance, healthcare, retail, manufacturing, and technology.</p>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="content">
                        <div className="sectionld">

                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>Productivity monitoring in the healthcare sector can help guarantee that staff members are abiding by HIPAA rules and aren't doing any actions that might jeopardize patient data.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>In the finance industry, productivity monitoring can assist in ensuring compliance with regulatory requirements and preventing insider threats.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>In the retail industry, productivity monitoring can help prevent employee theft and ensure that employees are adhering to company policies.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>In the manufacturing industry, productivity monitoring can help optimize resources and identify areas for improvement.</p>
                                </div>
                            </div>
                            <div className="modelprod">
                                <div>
                                    <img src={circle} alt="banking" />
                                </div>
                                <div>
                                    <p>In the technology industry, productivity monitoring can help prevent data breaches and ensure that employees are adhering to security best practices.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Productivity
