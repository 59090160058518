import React from 'react';
import './biometrics.css'
import banner from '../../../assets/ABB6 1.png'
import image1 from '../../../assets/bb-illustration.png'
import uses from '../../../assets/ABB6 2.png'
import circle from '../../../assets/Ellipse 685.png'
import common from '../../../assets/image 76.png'
import point from '../../../assets/Ellipsew.png'
import bank from '../../../assets/bank.png'

function Biometrics() {

    return (
        <>
            <div className="biometrics-1">
                <div className="gradientb"></div>
                <img src={banner} alt="solution" className="biometrics-banner" />
                <div className='bio-text'>
                    <p>Behavioral </p>
                    <p>Biometrics ZTA</p>
                </div>
            </div>

            <div className="biometrics-2">
                <img src={image1} alt="about" className="responsive-image_password" />
                <div className="bio-content">
                    <h3>What is Behavioral Biometrics ZTA ? </h3><br />
                    <p>
                        Behavioral biometrics measures and uniquely distinguishes patterns in the behavior of device users. The related techniques used to capture and evaluate biometric behaviors can be extremely effective in evaluating whether the correct individual is logging into an account, or whether the individual’s behavior is consistent with that person’s normal patterns as opposed to those of a fraudster.
                        <br /><br />Behavioral biometric verification methods include keystroke dynamics, swipe and touch analysis, mouse interactions and cognitive biometrics. They are distinct from physical biometrics, which involve measuring and identifying unique human attributes such as fingerprints, facial patterns, iris characteristics and voiceprints.
                    </p>
                </div>
            </div>

            <div className="bio_section5">
                <div className="bio_s5">

                    <div className="bio_cont">
                        <h4>How behavioral Biometrics can be used in cyber security?</h4>
                        <p className="main">Behavioral biometrics can be a powerful tool in the fight against cybercrime. Here's how it strengthens cybersecurity: </p>

                        <br />
                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Facial Recognition </h4>
                                        <p>Unlike traditional methods that verify at login, behavioral biometrics monitors user activity throughout a session. This ongoing analysis helps identify suspicious behavior even if an attacker gains initial access with stolen credentials.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Fraud Detection</h4>
                                        <p>By analyzing typing patterns, mouse movements, and other user interactions, behavioral biometrics can flag anomalies that might indicate fraud. This can prevent unauthorized transactions or account takeovers.</p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4 className="bank_text">Adaptable Security</h4>
                                        <p>Security protocols can be adjusted based on the user's behavior and the sensitivity of the action. For instance, high-risk actions like large financial transfers might trigger additional verification steps. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4 className="bank_text">Reduced Reliance on Passwords</h4>
                                        <p>Passwords are a constant target for attackers. Behavioral biometrics can offer an additional layer of security, making stolen passwords less valuable. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={circle} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4 className="bank_text">Insider Threat Detection</h4>
                                        <p>Deviations from a user's typical behavior patterns could indicate unauthorized access or malicious activity by someone with legitimate credentials. </p>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className='gradientu'></div>
                <img src={uses} alt="solution" className='uses' />
            </div>

            {/* <div class="alexa-3">

                <img src={common} alt="Common" class="common-image" />

                <div class="section">
                    <h5>Where can we use it</h5>
                    <p>Behavioral biometrics is transforming cybersecurity by providing dynamic and discrete protection through the monitoring of human behavior patterns such as typing rhythms and mouse movements. Its use goes beyond traditional industries, making it critical for improving security and authentication in a variety of settings and circumstances.</p>
                </div>
                <div class="sectionl">
                    <div className="text">
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Workstations in Secure Facilities</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">ATMs and Banking Kiosks</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Home Offices</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Airport Check-in and Boarding Gates</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Hospital Computer Stations</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Public Terminals</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">University Computer Labs and E-Learning Platforms</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Smartphones and Personal Devices</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Point-of-Sale Systems in Retail</h6>
                        </div>
                        <div className="modelu">
                            <img src={point} alt="banking" className="bank_image" />
                            <h6 className="bank_text">Control Rooms of Critical Infrastructure</h6>
                        </div>
                    </div>
                </div>

            </div> */}

            <div className="bio-use1">
                <div className="common-imagep">
                    <img src={common} alt="Imagew" />
                </div>
                <div className="top">
                    <div className="content">
                        <div className="sectiont">
                            <h5>Where can we use it?</h5>
                            <p>Behavioral biometrics is transforming cybersecurity by providing dynamic and discrete protection through the monitoring of human behavior patterns such as typing rhythms and mouse movements. Its use goes beyond traditional industries, making it critical for improving security and authentication in a variety of settings and circumstances. </p>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="content">
                        <div className="sectionld">
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Workstations in Secure Facilities</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">ATMs and Banking Kiosks</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Home Offices</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Airport Check-in and Boarding Gates</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Hospital Computer Stations</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Public Terminals</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">University Computer Labs and E-Learning Platforms</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Smartphones and Personal Devices</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Point-of-Sale Systems in Retail</h6>
                                </div>
                            </div>
                            <div className="modelu">
                                <div>
                                    <img src={point} alt="banking" className="bank_image" />
                                </div>
                                <div>
                                    <h6 className="bank_text">Control Rooms of Critical Infrastructure</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="biometrics-bene">
                <div className='head'>
                    <h3 style={{ color: 'black' }}>Benefits</h3>
                    <p>Behavioral biometrics offers several benefits in the realm of cybersecurity, particularly for protecting critical infrastructure.</p>
                </div>
                <div className="bio-bene">

                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Continuous Authentication</h6>
                            <p>It provides ongoing verification of a user's identity, enhancing security throughout a session, not just at the point of login.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Hard to Mimic</h6>
                            <p>Behavioral patterns are unique and complex, making them difficult to replicate or spoof, thus offering a higher level of security against impersonation attacks.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Non-Intrusive and User-Friendly</h6>
                            <p>The technology operates in the background, analyzing user behavior without disrupting their workflow, offering a seamless user experience.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Reduced Risk of Stolen Credentials</h6>
                            <p>Since authentication is based on behavior patterns rather than static information like passwords, the risk associated with stolen credentials is significantly reduced.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Early Fraud Detection</h6>
                            <p>By identifying deviations from established behavior patterns, behavioral biometrics can quickly flag potentially fraudulent activity.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Enhanced Privacy</h6>
                            <p>It does not require the storage of sensitive personal information, such as fingerprints or facial images, which could be invasive.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Adaptive Security</h6>
                            <p>It adjusts to changes in a user’s behavior over time, ensuring that the security measures remain effective even as habits evolve.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Cost-Effective</h6>
                            <p>Reduces the need for additional security personnel and hardware, as it can be integrated into existing systems.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Compliance Support</h6>
                            <p>Helps organizations comply with various data security regulations by providing robust authentication and fraud detection capabilities.</p>
                        </div>
                    </div>
                    <div className="model-bene">
                        <div className='borderm'></div>
                        <div className="text-container-m">
                            <h6>Insider Threat Detection</h6>
                            <p>Effective in identifying unusual activities that could signal malicious actions from within an organization.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="endingb">
                <h3>Use case in different industries</h3>
                <p className='head'>Zero Trust Architecture (ZTA) can be effectively utilized across a wide range of industries, especially those handling sensitive data or<br /> requiring stringent security measures. Here are some key industries where ZTA is particularly beneficial</p>
                <div className="adaptive_section12">

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Healthcare</h6>

                            <p>Protects patient data and healthcare systems from breaches, complying with regulations like HIPAA.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Banking and Finance</h6>

                            <p>Secures financial transactions and customer data, crucial for trust and regulatory compliance.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Government and Public Sector</h6>

                            <p>Ensuring security of customer data and transactions, crucial for main-taining consumer trust & complying with data protection regulations.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Telecommunications</h6>

                            <p>Protects networks and customer data in an industry that forms the backbone of modern communication.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Retail and E-Commerce</h6>

                            <p>Safeguards sensitive government data and critical infrastructure from various cyber threats.</p>
                        </div>
                    </div>

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Technology and IT Services</h6>

                            <p>Protects intellectual property and customer data, essential in an industry constantly targeted by cyberattacks.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Education Platforms</h6>

                            <p>Safeguards student and staff data, research information, and ensures secure online learning platforms.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Energy and Utilities</h6>

                            <p>Essential for protecting infrastructure that's critical to national security and public safety.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Manufacturing and Industrial</h6>

                            <p>Protects intellectual property and operational technology from cyber threats, crucial for business continuity and safety.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Media and Entertainment</h6>

                            <p>Protects intellectual property and customer data in an industry increasingly reliant on digital platforms.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Transportation and Logistics</h6>

                            <p>Secures data and systems in an industry vital to the global economy and everyday functioning of societies.</p>
                        </div>
                    </div>

                    <div className="model">
                        <div>
                            <img src={bank} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">E-commerce Platforms</h6>

                            <p>Safeguards user accounts and transactions.</p>
                        </div>
                    </div>


                </div>
            </div>


            {/* <div class="bio-use">
                <div class="common-imageb">
                    <img src={common} alt="Imagew" />
                </div>
                <div class="blue-section">
                    <div class="content">
                        <div class="sectiond">
                            <h5>Where can we use it</h5>
                            <p>Behavioral biometrics is transforming cybersecurity by providing dynamic and discrete protection through the monitoring of human behavior patterns such as typing rhythms and mouse movements. Its use goes beyond traditional industries, making it critical for improving security and authentication in a variety of settings and circumstances.</p>
                        </div>
                    </div>
                </div>
                <div class="another-section">
                    <div class="content">
                        <div class="sectionld">
                            <div className="text">
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Workstations in Secure Facilities</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">ATMs and Banking Kiosks</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Home Offices</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Airport Check-in and Boarding Gates</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Hospital Computer Stations</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Public Terminals</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">University Computer Labs and E-Learning Platforms</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Smartphones and Personal Devices</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Point-of-Sale Systems in Retail</h6>
                                </div>
                                <div className="modelu">
                                    <img src={point} alt="banking" className="bank_image" />
                                    <h6 className="bank_text">Control Rooms of Critical Infrastructure</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>  */}



        </>
    )
}

export default Biometrics;