import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import main from "../../assets/banner012x 1 (1).png";
import Picture from "../../assets/PA10.png";
import about from '../../assets/adapid.png';
import video from '../../assets/adapID_Intro_Draft.mp4';
import thumbnail from '../../assets/thumnail1.png';
import illustration from '../../assets/illustration.png';
import ztna from '../../assets/image 19.png';
import ztnamodel from '../../assets/home-ztna.png';
import client1 from "../../assets/Ellipse 673.png";
import client2 from "../../assets/Ellipse 674.png";
import comma1 from "../../assets/comma1.png";
import comma2 from "../../assets/comma2.png";
import seizure from '../../assets/seizure.png'
import cognitive from '../../assets/cognitive.png'
import autocontrol from '../../assets/autocontrol.png'
import smsmagic from '../../assets/sms-magic.png'
import ztnac from '../../assets/ztnac.png'
import behavior from '../../assets/behaviour.png'
import './home.css';

function Home() {

    const videoRef = useRef(null);

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function handleVideoVisibility() {
        const video = videoRef.current;

        if (video && isInViewport(video)) {
            video.muted = true;
            video.play().catch(error => {
                console.error("Failed to play:", error);
            });
        } else if (video) {
            video.pause();
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleVideoVisibility);
        return () => {
            window.removeEventListener('scroll', handleVideoVisibility);
        };
    }, []);


    return (
        <>
            <div className="container">
                <img src={main} alt="Backgroundimage" />
                <div className="text-overlay">
                    <p className="one">Behavioral <br />Biometrics</p>
                    <p className="two">AI powered HCI & Haptics based cyber threat detection and protection  </p>
                    <p className="three">Sophistication Simplified: Next-Gen Authentication & Authorization for a Zero Trust World </p>
                </div>
            </div>

            <div className="section1">
                <div className="content-container">
                    <div className="video-container" >
                        <video ref={videoRef}
                            poster={thumbnail}
                            controls
                            src={video}
                        />
                    </div>
                    <div className="content">
                        <img src={about} alt="about" className="responsive-image" />
                        <p>Detect and Prevent Sophisticated </p>
                        <p>Advanced Cyber Threats using</p>
                        <p>Behavioral Biometrics and AI.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section2">
                <p style={{ fontSize: '28px' }}>Our Offerings</p>
            </div>

            <div className="section3" >
                <div className="section-card">
                    <h3>AI adaptive behavioral Authentication</h3><br />
                    {/* <p>
                        adapID adaptive authentication represents a paradigm shift in secure authentication by using the principle of zero trust authentication and blending the nuanced power of behavioral biometrics with the precision of physiological biometrics to offer unparalleled security measures. This fusion not only harnesses the unique physical attributes of individuals, such as fingerprints and facial patterns, but also integrates a sophisticated analysis of user behavior patterns — from the way they interact with devices to their location.<br /><br />
                        By examining this rich tapestry of human traits and interactions, our product delivers a robust, multi-layered authentication framework that is not just secure, but also remarkably intuitive and seamless for the user. This approach ensures the highest level of security, effectively protecting identities and sensitive data in an increasingly digital world.
                    </p><br /> */}
                    <ul>
                        <li>
                            <p>adapID adaptive authentication represents a paradigm shift in secure authentication by using the principle of zero trust authentication and blending the nuanced power of behavioral biometrics with the precision of physiological biometrics to offer unparalleled security measures. </p>
                        </li>
                        <br />
                        <li>
                            <p>This fusion not only harnesses the unique physical attributes of individuals, such as fingerprints and facial patterns, but also integrates a sophisticated analysis of user behavior patterns — from the way they interact with devices to their location.</p>
                        </li>
                        <br />
                        <li>
                            <p>By examining this rich tapestry of human traits and interactions, our product delivers a robust, multi-layered authentication framework that is not just secure, but also remarkably intuitive and seamless for the user. </p>
                        </li>
                        <br />
                        <li>
                            <p>This approach ensures the highest level of security, effectively protecting identities and sensitive data in an increasingly digital world.</p>
                        </li>
                        <br />
                    </ul>
                    {/* <button className="show-more-button">Show More</button> */}
                    <Link to="/adaptive" className="btnr btn-rd"><button className="show-more-button">Show More</button></Link>
                </div>
                <img src={behavior} alt="about" className="responsive-image" />
            </div>

            <div className="section4" >
                <div className="left">
                    <img src={Picture} alt="picture1" className="homep" />
                </div>
                <div className="right">
                    <div className="text-container">
                        <div className="section4-div" >
                            <h3> Password-less Authentication</h3><br />
                            {/* <p>Introducing adapID password-less authentication system, expertly crafted to leverage the unique combination of behavioral and physiological biometrics, alongside device binding capabilities. This innovative approach is further enhanced by the adoption of a decentralized web protocol, ensuring that each step of the authentication process is fortified with double encoding and encryption. <br />
                                <br />What sets our product apart is the integration of an invisible keyboard feature for user input. This advanced technology guarantees that user inputs remain secure and protected, even when the onboarding process is initiated from a compromised device. By meticulously layering these state-of-the-art security measures, our solution not only simplifies the user experience by eliminating traditional passwords but also establishes a new standard in safeguarding user identities and sensitive data against the evolving threats in the digital realm.
                            </p> <br /> */}
                            <ul>
                                <li>
                                    <p>Introducing adapID password-less authentication system, expertly crafted to leverage the unique combination of behavioral and physiological biometrics, alongside device binding capabilities. </p>
                                </li>
                                <br />
                                <li>
                                    <p>This innovative approach is further enhanced by the adoption of a decentralized web protocol, ensuring that each step of the authentication process is fortified with double encoding and encryption. </p>
                                </li>
                                <br />
                                <li>
                                    <p>What sets our product apart is the integration of an invisible keyboard feature for user input. This advanced technology guarantees that user inputs remain secure and protected, even when the onboarding process is initiated from a compromised device.  </p>

                                </li>
                                <br />
                                <li>
                                    <p>By meticulously layering these state-of-the-art security measures, our solution not only simplifies the user experience by eliminating traditional passwords but also establishes a new standard in safeguarding user identities and sensitive data against the evolving threats in the digital realm.</p>
                                </li>
                                <br />
                            </ul>
                            <Link to="/passwordless" className="btnr btn-rd"><button className="show-more-button">Show More</button></Link>
                        </div>
                    </div>
                </div>
            </div >

            <div className="section5" >
                <div className="card-content">
                    <div className="text-container">
                        <h3>AI Continuous Authentication & Monitoring</h3><br />
                        {/* <p>adapID AI continuous authentication introduces an innovative approach to security and productivity by offering continuous session monitoring and authentication. Utilizing a sophisticated blend of behavioral biometrics, geographical location data, and an array of user behavioral insights, this solution ensures seamless, continuous authentication throughout user sessions. Beyond its core security functions, our product stands as a powerful tool for enhancing employee productivity.
                            <br /><br />By analyzing patterns in user behavior and location, it provides valuable insights into work habits, enabling organizations to identify opportunities for efficiency improvements and productivity enhancement. This dual-purpose solution not only fortifies security by ensuring that users are who they claim to be at all times but also offers a strategic advantage by shedding light on workforce dynamics, making it an indispensable asset for any forward-thinking company.</p><br /> */}
                        <ul>
                            <li>
                                <p>adapID AI continuous authentication introduces an innovative approach to security and productivity by offering continuous session monitoring and authentication.Utilizing a sophisticated blend of behavioral biometrics, geographical location data, and an array of user behavioral insights, this solution ensures seamless, continuous authentication throughout user sessions.</p>
                            </li>
                            <br />
                            <li>
                                <p>Beyond its core security functions, our product stands as a powerful tool for enhancing employee productivity.</p>
                            </li>
                            <br />
                            <li>
                              <p>By analyzing patterns in user behavior and location, it provides valuable insights into work habits, enabling organizations to identify opportunities for efficiency improvements and productivity enhancement. </p>
                            </li>
                            <br />
                            <li>
                                <p>This dual-purpose solution not only fortifies security by ensuring that users are who they claim to be at all times but also offers a strategic advantage by shedding light on workforce dynamics, making it an indispensable asset for any forward-thinking company.</p>
                            </li>
                            <br />
                        </ul>
                        <Link to="/continuous" className="btnr btn-rd"><button className="show-more-button">Show More</button></Link>
                    </div>
                    <img src={illustration} alt="picture1" className="responsive-cont" />
                </div>
            </div>

            <div className="section6" >
                <img src={ztnac} alt="about" />
                <div className="section-card">
                    <h3>AI Cloud ZTNA</h3><br />
                    {/* <p>
                        adapID is an advanced security platform offering Zero Trust Network Access (ZTNA) with a unique twist on Multi-Factor Authentication (MFA) through Behavioral Biometrics, ensuring seamless yet robust user verification. By eliminating passwords in favor of password-less authentication and employing micro-segmentation based on user roles, adapID provides tailored access to network applications, enhancing security beyond traditional VPNs.<br /><br />
                        Its compatibility with existing Identity Providers (IDPs) guarantees easy integration, streamlining deployment while offering a secure, user-friendly experience. This modern approach not only tightens security by minimizing the attack surface but also reduces the risk of phishing and internal threats, positioning adapID as a superior alternative to conventional VPN solutions.
                    </p><br /> */}
                    <ul>
                        <li>
                            <p>adapID is an advanced security platform offering Zero Trust Network Access (ZTNA) with a unique twist on Multi-Factor Authentication (MFA) through Behavioral Biometrics, ensuring seamless yet robust user verification. </p>
                        </li>
                        <br />
                        <li>
                            <p>By eliminating passwords in favor of password-less authentication and employing micro-segmentation based on user roles, adapID provides tailored access to network applications, enhancing security beyond traditional VPNs.</p>
                        </li>
                        <br />
                        <li>
                            <p>Its compatibility with existing Identity Providers (IDPs) guarantees easy integration, streamlining deployment while offering a secure, user-friendly experience.</p>
                        </li>
                        <br />
                        <li>
                            <p>This modern approach not only tightens security by minimizing the attack surface but also reduces the risk of phishing and internal threats, positioning adapID as a superior alternative to conventional VPN solutions.</p>
                        </li>
                        <br />
                    </ul>
                    <Link to="/ztna" className="btnr btn-rd"><button className="show-more-button">Show More</button></Link>
                </div>

            </div>

            <div className="section7">
                <div className="left">
                    <img src={ztna} alt="picture1" />
                </div>
                <div className="right">
                    <div className="text-container">
                        <h3>adapID “your shield against cyber threats” </h3><br />
                        <p>adapID is a comprehensive cybersecurity solution that safeguards against cyber threats through robust authentication, precise authorization, and advanced protection against social engineering attacks, ensuring your digital safety in a vulnerable online world. <br />
                        </p>
                    </div>
                </div>

            </div>
            <div className="white-card" >
                <img src={ztnamodel} alt="model" />
            </div>

            <h3 className="trust">Trusted by</h3>
            <div className="trusted">
                <div className="trusted-card">
                    <img src={seizure} alt="dashboard" />
                </div>
                <div className="trusted-card">
                    <img src={cognitive} alt="model" />
                </div>
                <div className="trusted-card">
                    <img src={autocontrol} alt="deploy" />
                </div>
                <div className="trusted-card">
                    <img src={smsmagic} alt="chatbot" />
                </div>
            </div>

            <div className="client-talk">
                <div className="text">What our clients say </div>
                <div className="client-card">
                    <img src={comma1} alt="comma" className="comma1" />
                    <div className="card1">
                        <div className="client-1">
                            <img src={client1} alt="picture1" />
                            <div className="client-text1">
                                <p className="para1">Kalaiselvan Palani</p>
                                <p className="para2">VP Engineering, SMS-Magic</p>
                            </div>
                        </div>
                        <div className="border"></div>
                        <div className="main-para">
                            DeepAlgorithms has been an excellent partner for SMS-Magic, providing comprehensive planning, up-front designs, feasibility analyses, architecture and infrastructure models for high-scale production and clear milestones. They have consistently met deadlines with quality product. Their expertise with AWS and DevOps has been invaluable as well. Communication has been excellent, so we have been able to identify and resolve issues in a clear and straightforward manner. They are professional, collaborative and a pleasure to work with.
                        </div>
                    </div>

                    <div className="card1">
                        <div className="client-1">
                            <img src={client2} alt="picture1" />
                            <div className="client-text1">
                                <p className="para1">Robert Moss</p>
                                <p className="para2"> President/Co-Founder, SeizureTracker.com</p>
                            </div>
                        </div>
                        <div className="border"></div>
                        <div className="main-para">
                            It is a pleasure working with the Deep Algorithms Solutions (DAS) team. No matter how complex the project, they deliver a quality product in a timely manner.
                            <br /> <br />
                            I'm continually amazed at the DeepAlgorithms team's capability to provide such a wide range of services in a very professional manner. We look forward to working with DeepAlgorithms for years to come!
                        </div>
                    </div>
                    <img src={comma2} alt="comma" className="comma2 responsive-image" />
                </div>
            </div>
        </>
    );
}

export default Home;
