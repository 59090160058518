import React, { useRef, useEffect } from "react";
import './passwordless.css';
import password from '../../../assets/password.png'
import password1 from '../../../assets/password1.png'
import security from '../../../assets/vulnerability.png'
import userdiff from '../../../assets/unlike.png'
import cost from '../../../assets/cost.png'
import risk from '../../../assets/risk1.png'
import workflow from '../../../assets/workflow.png'
import techno from '../../../assets/techno.png'
import cyber from '../../../assets/cyber.png'
import regular from '../../../assets/regular.png'
import solution from '../../../assets/solution1.png'
import point from '../../../assets/Ellipsew.png'
import video from '../../../assets/video (5).png'
import fingerprint from '../../../assets/fingerprint.png'
import union from '../../../assets/Union (4).png'
import line1 from '../../../assets/Line 16.png'
import video1 from '../../../assets/Biometric scanner.mp4'


function Passwordless() {

    const videoRef = useRef(null);

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function handleVideoVisibility() {
        const video = videoRef.current;

        if (video && isInViewport(video)) {
            video.muted = true;
            video.play().catch(error => {
                console.error("Failed to play:", error);
            });
        } else if (video) {
            video.pause();
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleVideoVisibility);
        return () => {
            window.removeEventListener('scroll', handleVideoVisibility);
        };
    }, []);

    return (
        <>
            <div className="adaptive_container">
                <div className="gradient"></div>
                <img src={password} alt="Backgroundimage" />
                <div className="adaptive_text">
                    <p className="one">AI Password-less Authentication</p>
                    <p className="two">Approach that allows users to access systems, accounts, or devices without the use of traditional passwords.</p>
                </div>
            </div>

            <div className="password_section1">
                <img src={password1} alt="about" className="responsive-image_password" />
                <div className="section-card_password">
                    <h3>What is AI Password-less Authentication ?</h3><br />
                    <p>
                        adapID leverages unique mobile phone attributes for secure user authentication and transaction authorization via a novel Decentralized Web Protocol. This protocol merges three security secrets to encrypt information in a way that only the initiating user’s device and the original webserver can decrypt, ensuring a one-way security vector. It employs a two-stage encryption process that keeps these secrets safeguarded, making decryption with current technology virtually impossible and any potentially decrypted data meaningless and unusable.
                        <br /><br />The system is effortlessly integrated into an organization's webserver interface, requiring no setup or software from users. It features an encrypted invisible keyboard for data capture, enabling passwordless web authentication and single-step MFA, all while being scalable to serve markets with hundreds of millions of users. Importantly, adapID does not store personal identifiable information, ensuring compliance with GDPR/CCPA, and facilitates the deployment of Passkeys (FIDO2/WebAuthn) with no need for additional development.
                    </p>
                </div>
            </div>

            <div className="ending" style={{ backgroundColor: '#F8F9FA' }}>
                <h3 style={{ color: 'black' }}>What's the need?</h3>
                <div className="pass_section12">

                    <div className="model">
                        <div>
                            <img src={security} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Security Weaknesses</h6>

                            <p>Passwords are often vulnerable to attacks.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={userdiff} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">User Difficulty</h6>

                            <p>Managing multiple passwords is challenging for users.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={cost} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Costly Management</h6>

                            <p>Organizations face high costs in managing password systems.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={risk} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Data Breach Risks</h6>

                            <p>Password vulnerabilities can lead to significant data breaches.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={workflow} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Workflow Inefficiency</h6>

                            <p>Password systems can slow down access to resources.</p>
                        </div>
                    </div>

                    <div className="model">
                        <div>
                            <img src={techno} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Technological Advancements</h6>

                            <p>Modern digital interactions are better suited to password-less methods.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={cyber} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Evolving Cyber Threats</h6>

                            <p>Passwords alone are insufficient against sophisticated cyber-attacks.</p>
                        </div>
                    </div>
                    <div className="model">
                        <div>
                            <img src={regular} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Regulatory Compliance</h6>

                            <p>Password-less methods can better meet data protection.</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="password_section5">

                <img src={solution} alt="solution" className="solution" />
                <div className="gradient-passwordless"></div>
                <div className="password_s5">
                    <div className="text_password">
                        <h3>Solution</h3>
                        <p style={{ marginTop: '10px' }}>Solutions for implementing password-less authentication typically involve various technologies and strategies that replace traditional passwords.</p>
                        <br />
                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={point} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Behavioral Biometrics </h4>
                                        <p>adapID utilizes behavior of the user’s to authenticate them, using behavioral factors such as hand-eye coordination, swipe behavior, navigating behavior etc. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={point} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Device Binding </h4>
                                        <p>Device binding enhances password-less authentication by tying user credentials securely to a specific device, ensuring that only authenticated devices can access an account or system. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={point} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>Physiological Biometrics </h4>
                                        <p>physiological biometrics bolster password-less authentication by leveraging a user's unique biological characteristics, seamlessly verifying identity for secure access without traditional passwords. </p>
                                    </div>
                                </div>
                            </li>
                            <br />
                            <li>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <img src={point} alt="circle" />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <h4>FIDO2/WebAuthn</h4>
                                        <p>FIDO2/WebAuthn supports password-less authentication by utilizing standardized protocols to securely authenticate users with their devices, eliminating the need for passwords while enhancing security and user convenience. </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div className="adaptive_section6">
                <h3>How it works ?</h3>
                <p style={{ marginTop: '1.5%' }}>Password-less authentication is a method of verifying a user's identity without using a password. Instead, it utilizes<br />
                    more secure alternatives such as possession factors  (e.g., one-time passwords, registered smartphones) or biometrics</p>
            </div>

            <div className="section-video">
                <div className="content-container">
                    <div className="video-container" >
                        <video ref={videoRef}
                            poster={video}
                            controls
                            src={video1}
                        />
                    </div>
                </div>
            </div>

            <div className="password_section10">
                <h3>Benefits</h3>
            </div>
            <div className="password_section11">
                <div className="password_benefits_image">
                    <img src={union} alt="benefits" className="benefits_image1" />
                    <div className="union1">
                        <p>ENHANCED<br />SECURITY</p>
                    </div>
                    <img src={line1} alt="benefits" className="line1" />
                    <div className="uniont">
                        <p>Offers stronger protection againstphishing and brute-force attacks, reducing the risk of password theft.</p>
                    </div>

                    <div className="union2">
                        <p>IMPROVED<br />USER<br />EXPERIENCE</p>
                    </div>
                    <img src={line1} alt="benefits" className="line2" />
                    <div className="uniont1">
                        <p>Eliminates the need for password memorization and management, simpl-ifying the login process and enhancing productivity.</p>
                    </div>

                    <div className="union3">
                        <p>COST<br />REDUCTION</p>
                    </div>
                    <img src={line1} alt="benefits" className="line3" />
                    <div className="uniont2">
                        <p>Decreases the long-term costs associated with password management, storage, and IT support.</p>
                    </div>

                    <div className="union4">
                        <p>BRUTE-FORCE<br />ATTACK<br />PROTECTION</p>
                    </div>
                    <img src={line1} alt="benefits" className="line4" />
                    <div className="uniont3">
                        <p>More resistant to brute-force attacks compared to traditional passwords.</p>
                    </div>

                    <div className="union5">
                        <p>STRONGER<br />CYBERSECURITY<br />POSTURE</p>
                    </div>
                    <img src={line1} alt="benefits" className="line5" />
                    <div className="uniont4">
                        <p>Bolsters an organization's overall cybersecurity defenses against various threats.</p>
                    </div>

                </div>
            </div>

            <div className="ending" style={{ backgroundColor: '#0068AC' }}>
                <h3>Where it fits?</h3>
                <p style={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>Password-less Authentication fits into various aspects of cybersecurity and IT infrastructure,<br />
                    enhancing security and user experience in several key areas:</p>
                <div className="adaptive_sectionp">

                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Corporate IT Systems</h6>


                        </div>
                    </div>
                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Online Services and E-Commerce</h6>


                        </div>
                    </div>
                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Banking and Financial Institutions</h6>


                        </div>
                    </div>
                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Healthcare Data Access</h6>


                        </div>
                    </div>
                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Government Services</h6>


                        </div>
                    </div>

                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Education Platforms</h6>


                        </div>
                    </div>
                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Remote Work Environments</h6>


                        </div>
                    </div>
                    <div className="modelp">
                        <div>
                            <img src={fingerprint} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Personal Device Security</h6>


                        </div>
                    </div>
                </div>

            </div>


        </>

    );
}

export default Passwordless;