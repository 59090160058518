import React from "react";
import { useRef } from 'react';
import './ztna.css';
import security from '../../../assets/security.png'
import ztnaillust from '../../../assets/ztna-illustration.png'
import ztna from '../../../assets/ZTNA.png'
import point from '../../../assets/Ellipsew.png'
import video from '../../../assets/video (7).png'
import ztnamodel from '../../../assets/image 18.png';
import encrypted from '../../../assets/encrypted (1).png'
import triangle1 from '../../../assets/Rectangle 3125.png'
import triangle2 from '../../../assets/Rectangle 3124.png'
import hexagon from '../../../assets/Polygon 8.png'
import hexagonb from '../../../assets/Polygon 14.png'
import hexagond from '../../../assets/Polygon 15.png'
import design from '../../../assets/attachment.png'
import uses from '../../../assets/ABB6 2.png'


function Ztna() {

    const videoRef = useRef(null);

    return (
        <>
            <div className="adaptive_container">
                <div className="gradient"></div>
                <img src={security} alt="Backgroundimage" />
                <div className="adaptive_text">
                    <p className="one">AI Cloud ZTNA</p>
                    <p className="two">A security concept based on the idea that businesses shouldn't blindly trust anything.</p>
                </div>
            </div>

            <div className="password_section1">
                <img src={ztnaillust} alt="about" className="responsive-image_password" />
                <div className="section-card_password">
                    <h3>What is AI Cloud ZTNA?</h3><br />
                    <p>
                        Zero Trust is a security concept based on the idea that businesses shouldn't blindly trust anything that's seeking to connect to their systems, either from within or beyond their boundaries.
                        It's is a method where resources are accessed with rigorous identity verification and on a "need-to-know" basis, regardless of whether the access request originates from inside or outside the network.
                        ZTNA differs from virtual private networks (VPNs) in that they grant access only to specific services or applications, where VPNs grant access to an entire network. As an increasing number of users access resources from home or elsewhere, ZTNA solutions can help eliminate gaps in other secure remote access technologies and methods.
                    </p>
                </div>
            </div>

            <div className="design-image">
                <img src={design} alt="design" />
            </div>

            <div className="parent1">
                <div className="ztna_section5">
                    <div className="ztna_s5">
                        <div className="text_cont">
                            <h3>Solution</h3>
                            <br />
                            <ul>
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>App Segmentation</h4>
                                            <p>ZTNA enhances security by limiting users to specific applications through app segmentation.</p>
                                        </div>
                                    </div>
                                </li>
                                <br />
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>App Isolation</h4>
                                            <p> Isolating application from network access.</p>
                                        </div>
                                    </div>
                                </li>
                                <br />
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>Outbound connections</h4>
                                            <p>Employing outbound-only connections to obscure infrastructure from unauthorized users.</p>
                                        </div>
                                    </div>
                                </li>
                                <br />
                                <li>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <img src={point} alt="circle" />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4>Shifting focus</h4>
                                            <p>Shifting focus from network to user-to-application access.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <img src={ztna} alt="solution" className="responsive-image" />
                </div>
                {/* <div className="sectionz">
                    <div className="content-container">

                        <div className="video-container">
                            <video ref={videoRef}
                                poster={video}
                                controls
                                src={video}
                            />
                        </div>
                        <div className="sectionz-text">
                            <h2>How it works ?</h2>
                            <p style={{ marginTop: '10px' }}>Once authorized, the ZTNA enables the user access to the targeted application via a secure, encrypted tunnel, which provides an additional degree of security protection by hiding apps and services from otherwise accessible IP addresses. </p>

                        </div>
                    </div>
                </div> */}
            </div>

            <div className="sectionzv" >
                <div className="content-container">
                    
                    <div className="video-container" >
                        {/* <video ref={videoRef}
                            poster={video}
                            controls
                            src={video1}
                        /> */}
                      
                        <img src={video} className="gif"/>
                    </div>

                    <div className="content">
                        <h2>How it works ?</h2>
                        <p style={{ marginTop: '10px' }}>Once authorized, the ZTNA enables the user access to the targeted application via a secure, encrypted tunnel, which provides an additional degree of security protection by hiding apps and services from otherwise accessible IP addresses.</p>
                    </div>

                </div>
            </div>

            <div className="white-card" style={{marginTop:'40px'}}>
                <img src={ztnamodel} alt="model" />
            </div>

            <div className="ztna-benefits">
                <img src={triangle1} className="triangle1" alt="triangle" />
                <img src={triangle2} className="triangle2" alt="triangle" />

                <h3 style={{ color: 'white', textAlign: 'center', padding: '5vh' }}>Benefits</h3>

                <div className="ztna_sectionb">
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Zero-trust approach</h6>
                            <p>Continuously verifies user and device identity and health before granting access,minimizing risks associated with implicit trust and lateral movement.</p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Micro-segmentation</h6>
                            <p>Isolates applications and data,preventing the spread of ransomware and other threats even if a device is compromised.</p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Minimized Attack Surface</h6>
                            <p>By adopting a "never trust, always verify" approach, ZTNA minimizes the potential attack surface, making it harder for unauthorized users to gain access to network resources. </p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Secure Remote Access</h6>
                            <p>Provides secure access to corporate applications and services for remote workers without the need for a traditional VPN, which often grants broader network access than necessary. </p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Improved User Experience</h6>
                            <p>Offers a seamless and secure access experience for users, regardless of their location or the devices they are using, without the need for cumbersome VPN connections. </p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Simplified Security Infrastructure</h6>
                            <p>By consolidating access control and security policies within a single framework, ZTNA simplifies the security infrastructure, reducing complexity and operational overhead. </p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Scalability</h6>
                            <p>Adapts to changing user and application needs more readily.</p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Better visibility into application activity</h6>
                            <p>Provides insights for monitoring, planning, and auditing.</p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Simplified administration</h6>
                            <p>Streamlines day-to-day management tasks compared to VPNs.</p>
                        </div>
                    </div>
                    <div className="modelzb">
                        <div className="text-container-m">
                            <h6 className="bank_text">Stronger compliance posture</h6>
                            <p>Aligns with zero-trust security principles and data protection regulations.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="last" style={{ backgroundColor: '#F8F9FA' }}>
                <h3 style={{ color: 'black' }}>Where it fits?</h3>
                <p className="modelz-head">Zero Trust Network Access (ZTNA) is not industry-specific and can be used in a variety of areas.<br />
                    Its adaptability and strong security approach make it appropriate for almost any business<br />
                    that requires secure access to digital resources and sensitive data protection.</p>
                <div className="ztna_section12">
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Healthcare Data Access</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Retail and E-commerce</h6>


                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Technology and IT Services</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Financial Services</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Telecommunications</h6>
                        </div>
                    </div>

                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Energy and Utilities</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Legal and Professional Services</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Media and Entertainment</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Transportation and Logistics</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Real Estate</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Manufacturing and Industrial</h6>
                        </div>
                    </div>
                    <div className="modelz">
                        <div>
                            <img src={encrypted} alt="banking" className="bank_image" />
                        </div>
                        <div className="text-container-m">
                            <h6 className="bank_text">Education Institutions</h6>
                        </div>
                    </div>
                </div>

            </div>

        </>

    );
}

export default Ztna;